import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { useParams, Route, Routes } from 'react-router-dom';
import { Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useQuery } from '@apollo/client';

import { Breadcrumbs, BurgerMenu, Header } from '../../components';
import { GET_MY_SITE_QUERY } from '../../gql';
import { SiteActionBar } from './SiteActionBar';
import { SiteMenu } from './SiteMenu';
import { SiteSettings } from './SiteSettings';
import { SiteFinds } from './SiteFinds';
import { SiteObjects } from './SiteObjects';
import { SiteAccess } from './SiteAccess';
import { FindSettings } from '../../components/FindSettings';

const Site = React.memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [findListMode, setFindListMode] = useState<'grid' | 'gallery'>('grid');

  const { siteId } = useParams();
  const { called, data, loading } = useQuery(GET_MY_SITE_QUERY, {
    variables: { siteId },
    fetchPolicy: 'cache-first',
    skip: !siteId,
  });

  const site = data?.getMySite;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Helmet title="Sites | Acolyte"></Helmet>
      <Header mainIcon={isMobile && <BurgerMenu menu={<SiteMenu />} />} />
      <Container maxWidth="xl">
        <Stack direction="row" gap={2}>
          {!isMobile && <SiteMenu />}
          <Stack flexGrow={1} gap={1}>
            <Stack
              direction={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              alignItems={isMobile ? 'flex-start' : 'center'}
            >
              <Breadcrumbs />
              <SiteActionBar
                findListMode={findListMode}
                setFindListMode={setFindListMode}
              />
            </Stack>
            <Routes>
              <Route
                index
                element={
                  <SiteSettings
                    siteId={siteId}
                    called={called}
                    loading={loading}
                    site={site}
                  />
                }
              />
              <Route
                path="finds/create"
                element={<FindSettings forSite={siteId} />}
              />
              <Route
                path="finds"
                element={<SiteFinds findListMode={findListMode} />}
              />
              <Route path="objects" element={<SiteObjects />} />
              <Route path="access" element={<SiteAccess />} />
            </Routes>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
});

export { Site };
