import React, { forwardRef } from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DRAWER_BLEEDING as drawerBleeding } from '../../consts';

const containerStyles = {
  position: 'relative',
  overflow: 'hidden',
  flexGrow: 1,
  flexShrink: 1,
} as any;

const SceneBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isMobile',
})<BoxProps & { $isMobile: boolean }>(({ theme, $isMobile }) => ({
  height: $isMobile ? `calc(100% - ${drawerBleeding}px)` : '100%',
}));

const SceneContainer = forwardRef<
  HTMLDivElement,
  {
    isMobile: boolean;
    children: React.ReactNode;
  }
>(({ children, isMobile = false }, ref) => (
  <SceneBox ref={ref} $isMobile={isMobile} sx={containerStyles}>
    {children}
  </SceneBox>
));

export { SceneContainer };
