import React from 'react';
import { MapTools, WorkspaceContext } from '.';

type MjollnirEvent = {
  srcEvent: {
    originalEvent: PointerEvent;
    preventDefault: () => void;
  };
};

function useWorkspace() {
  const context = React.useContext(WorkspaceContext);

  if (!context) {
    throw new Error(`useWorkspace must be used within a WorkspaceProvider`);
  }

  const {
    store,
    addMarkerPoint,
    deleteMarkerPoint,
    clearMarkerPoints,
    setActiveMapTool,
    setSharedMap,
    onShareMap,
    onShareMapDialogClose,
  } = context;

  const enableMarkerPointTool = () => {
    setActiveMapTool(MapTools.ADD_MARKER_POINT_TOOL);
  };

  const enableDefaultTool = () => {
    setActiveMapTool(null);
  };

  const onClickMap = (
    { coordinate }: { coordinate: [number, number] },
    event: MjollnirEvent
  ) => {
    switch (store.activeMapTool) {
      case MapTools.ADD_MARKER_POINT_TOOL:
        addMarkerPoint({
          coordinate,
          label: 'New point',
        });
        event.srcEvent.preventDefault();
        break;
      default:
        break;
    }
  };

  return {
    store,
    onShareMap,
    onShareMapDialogClose,
    setSharedMap,
    addMarkerPoint,
    deleteMarkerPoint,
    clearMarkerPoints,
    enableMarkerPointTool,
    enableDefaultTool,
    onClickMap,
  };
}

export { useWorkspace };
