// eslint-disable
import React, { useCallback } from 'react';
import { getItem, setItem } from '../../io';

type LayerValue = null | string;

function createCtx(defaultValue: LayerValue) {
  type SetLayer = React.Dispatch<React.SetStateAction<typeof defaultValue>>;

  const defaultUpdate: SetLayer = () => defaultValue;

  const ctx = React.createContext({
    layer: defaultValue,
    setLayer: defaultUpdate,
  });

  function LayerProvider(props: React.PropsWithChildren<{}>) {
    const [layer, setLayer] = React.useState(defaultValue);
    const setNewLayer = useCallback(
      (newLayer) => {
        setLayer(newLayer);
        setItem('savedLayer', newLayer);
      },
      [setLayer]
    );
    const value = React.useMemo(() => ({ layer, setLayer: setNewLayer }), [
      layer,
      setNewLayer,
    ]);

    return <ctx.Provider value={value} {...props} />;
  }

  return [ctx, LayerProvider] as [typeof ctx, typeof LayerProvider];
}

const savedLayer = typeof window !== 'undefined' ? getItem('savedLayer') : null;

const [LayerContext, LayerProvider] = createCtx(savedLayer);

export { LayerContext, LayerProvider };
