import React, { useCallback, useState } from 'react';
import Helmet from 'react-helmet';
import { Stack, Typography } from '@mui/material';
import DeckGL from '@deck.gl/react';
import { LinearInterpolator } from '@deck.gl/core';
import Map from 'react-map-gl';

import { LogoIcon } from '../Icons';
import {
  MAPBOX_ACCESS_TOKEN,
  WELCOME_SCREEN_MAPBOX_STYLE_URL,
} from '../../consts';

const transitionInterpolator = new LinearInterpolator([
  'latitude',
  'longitude',
]);

interface ViewState {
  latitude: number;
  longitude: number;
  [k: string]: any;
}

const floatingHeaderStyles = {
  zIndex: 2,
  position: 'absolute',
  width: '100%',
  height: '100%',
};
const logoStyles = { fontSize: 96 };
const headingStyles = {
  textShadow: '2px 2px 4px rgba(0,0,0,0.8)',
  fontSize: [64, 96],
};

// Viewport settings
const INITIAL_VIEW_STATE = {
  latitude: 50.13242,
  longitude: 14.908903,
  zoom: 14,
  pitch: 0,
  bearing: 0,
};

const getLatitudeNext = (latitude: number) => {
  if (latitude === 50.13242) {
    return 50.1370222;
  }

  if (latitude === 50.1370222) {
    return 49.3698697;
  }

  return 50.13242;
};

const getLongitudeNext = (latitude: number) => {
  if (latitude === 50.13242) {
    return 15.6316969;
  }

  if (latitude === 50.1370222) {
    return 14.4567025;
  }

  return 14.908903;
};

const WelcomeScreen = () => {
  const [currentViewState, setCurrentViewState] = useState<ViewState>(
    INITIAL_VIEW_STATE
  );

  const onTransitionEnd = useCallback(() => {
    setCurrentViewState((viewState) => ({
      ...viewState,
      latitude: getLatitudeNext(viewState.latitude),
      longitude: getLongitudeNext(viewState.latitude),
      onTransitionEnd,
      transitionDuration: 240000,
      transitionInterpolator,
    }));
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Helmet title="Acolyte"></Helmet>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={floatingHeaderStyles}
      >
        <LogoIcon sx={logoStyles} />
        <Typography
          textAlign="center"
          fontWeight="bold"
          variant="h1"
          color="white"
          sx={headingStyles}
        >
          Acolyte
        </Typography>
      </Stack>
      <DeckGL
        onLoad={onTransitionEnd}
        initialViewState={currentViewState}
        controller={true}
      >
        <Map
          mapStyle={WELCOME_SCREEN_MAPBOX_STYLE_URL}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        />
      </DeckGL>
    </div>
  );
};

export { WelcomeScreen };
