import {
  MRT_ColumnDef,
  useMaterialReactTable,
  MRT_TableContainer as MrtContainer,
} from 'material-react-table';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import {
  ADD_SITE_COLLABORATOR_MUTATION,
  GET_SITE_COLLABORATORS_QUERY,
  REMOVE_SITE_COLLABORATOR_MUTATION,
} from '../../../gql';

export type Person = {
  displayName: string;
  email: string;
  createdAt: string;
  level: number;
};

const columns: MRT_ColumnDef<Person>[] = [
  {
    accessorKey: 'displayName',
    header: 'Name',
  },
  {
    accessorKey: 'email',
    header: 'E-mail',
  },
  {
    accessorKey: 'level',
    header: 'Level',
  },
  {
    Cell: ({ cell }) =>
      cell.getValue<Date | null>()
        ? cell.getValue<Date>()?.toLocaleDateString()
        : '-',
    accessorFn: (row) => row.createdAt && new Date(row.createdAt),
    header: 'Added on',
    size: 120,
  },
];

const gridStyles = {
  display: 'grid',
  padding: 2,
};
const inputStyles = { width: 280, padding: 0 };
const levelInputStyles = { width: 100, padding: 0 };

const levels = [0, 1, 2];
const defaultValues = { collaborator: null, level: 1 };
const isOptionEqualToValue = (o: { id?: string }, v: { id?: string }) =>
  o?.id === v?.id;

const AccessGrid = ({ data, contacts, siteId }) => {
  const activeCollaborators = data.map((ac) => ac.userId);
  const filteredCollaborators = contacts.filter(
    (c) => !activeCollaborators.includes(c.responderUser)
  );
  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableColumnActions: false,
    enableColumnFilters: false,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <IconButton onClick={() => openDeleteConfirmModal(row)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    ),
    initialState: {
      pagination: { pageSize: 50, pageIndex: 0 },
      density: 'compact',
    },
  });

  const {
    reset,
    control,
    handleSubmit,
    formState,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  const [
    addCollaborator,
    { called: calledAdd, loading: loadingAdd },
  ] = useMutation(ADD_SITE_COLLABORATOR_MUTATION, {
    refetchQueries: [
      {
        query: GET_SITE_COLLABORATORS_QUERY,
        variables: { siteId, pagination: { limit: 1000 } },
      },
    ],
  });

  const [removeCollaborator] = useMutation(REMOVE_SITE_COLLABORATOR_MUTATION, {
    refetchQueries: [
      {
        query: GET_SITE_COLLABORATORS_QUERY,
        variables: { siteId, pagination: { limit: 1000 } },
      },
    ],
  });

  const onSubmit = async (data) => {
    const userId = data?.collaborator?.responderUser;
    const level = data?.level;

    await addCollaborator({
      variables: {
        siteId,
        userId,
        level,
      },
    });
    // add yup validation and error handling and displaying
    reset({ collaborator: null, level });
  };

  const openDeleteConfirmModal = async (row) => {
    if (
      window.confirm(
        `Are you sure you want to stop sharing this site with ${row.original.email}?`
      )
    ) {
      await removeCollaborator({
        variables: { siteId, userId: row.original.userId },
      });
    }
  };

  return (
    <Paper sx={gridStyles}>
      {/* Our Custom External Top Toolbar */}
      <Box
        sx={(theme) => ({
          display: 'flex',
          backgroundColor: 'inherit',
          flexDirection: 'row',
          gap: '16px',
          '@media max-width: 768px': {
            flexDirection: 'column',
          },
        })}
      >
        <Stack
          gap={2}
          direction={{ xs: 'column', sm: 'row' }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          alignItems="center"
        >
          <Controller
            name="collaborator"
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                {...field}
                disablePortal
                id="site-collaborator-user"
                defaultValue={null}
                isOptionEqualToValue={isOptionEqualToValue}
                options={filteredCollaborators}
                disabled={filteredCollaborators.length === 0}
                getOptionLabel={(option: {
                  id: string;
                  email: string;
                  displayName: string;
                }) =>
                  `${option.email}${
                    option.displayName ? ` (${option.displayName})` : ''
                  }`
                }
                onChange={(event, newValue) => onChange(newValue)}
                renderInput={(params) => (
                  <TextField sx={inputStyles} inputRef={ref} {...params} />
                )}
                sx={inputStyles}
              />
            )}
          />
          <Typography ml={2} variant="body2">
            with level:
          </Typography>
          <Controller
            name="level"
            disabled={filteredCollaborators.length === 0}
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                disablePortal
                id="site-collaborator-level"
                options={levels}
                getOptionLabel={(o) => `${o}`}
                defaultValue={1}
                onChange={(event, newValue) => onChange(newValue)}
                renderInput={(params) => (
                  <TextField
                    sx={levelInputStyles}
                    inputRef={ref}
                    {...params}
                    {...field}
                  />
                )}
                sx={levelInputStyles}
              />
            )}
          />
          <Button
            disabled={loadingAdd}
            type="submit"
            color="primary"
            variant="contained"
          >
            Add collaborator
          </Button>
        </Stack>
      </Box>
      <Typography mt={1} mb={2} variant="body2" p="4px">
        Any contact added will be able to see all site's finds. Level 0 means
        readonly, Level 1 can add finds, Level 2 can edit all site's finds.
      </Typography>
      <MrtContainer table={table} />
    </Paper>
  );
};

export default AccessGrid;
