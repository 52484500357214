import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { DateTime } from 'luxon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const cardStyles = { maxWidth: 200, minWidth: 150, flexGrow: 1, flexShrink: 1 };
const moreButtonStyles = { marginLeft: 'auto' };

const Contact = ({ data: { createdAt, email, displayName } }) => (
  <Card sx={cardStyles}>
    <CardActionArea>
      <CardContent>
        <Typography variant="body1" gutterBottom component="div">
          {email}
        </Typography>
        <Typography
          fontWeight={displayName ? 'bold' : 100}
          height={30}
          component="div"
          color={displayName ? 'text.primary' : 'text.secondary'}
        >
          {displayName || '—'}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          gutterBottom
          component="div"
        >
          Added {DateTime.fromISO(createdAt).toRelative({ locale: 'en' })}
        </Typography>
      </CardContent>
    </CardActionArea>
    <CardActions disableSpacing>
      <Button size="small" color="primary">
        Details
      </Button>
      <IconButton sx={moreButtonStyles} aria-label="more">
        <MoreHorizIcon />
      </IconButton>
    </CardActions>
  </Card>
);

export { Contact };
