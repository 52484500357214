import {
  Alert,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Input,
  InputLabel,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import React from 'react';

import { CREATE_CONTACT_MUTATION, GET_MY_CONTACTS_QUERY } from '../../gql';
import { useMutation } from '@apollo/client';

const labelStyles = { fontWeight: 500, fontSize: 12 };
const inputStyles = { width: '100%', padding: 0 };
const successAlertStyles = { width: '100px', '& > div': { padding: 0 } };

const CreateContactCard = () => {
  const {
    reset,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: { email: '' },
  });

  const [create, { called, loading }] = useMutation(CREATE_CONTACT_MUTATION, {
    refetchQueries: [
      {
        query: GET_MY_CONTACTS_QUERY,
      },
    ],
  });

  const onSubmit = async (data) => {
    await create({
      variables: {
        email: data.email,
      },
    });

    // add yup validation and error handling and displaying
    reset({ email: '' });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ width: 300 }}>
        <CardActionArea>
          <CardContent>
            <Typography variant="body2" gutterBottom component="div">
              Send contact request
            </Typography>
            <InputLabel sx={labelStyles} htmlFor="email">
              Email
            </InputLabel>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input id="email" type="text" sx={inputStyles} {...field} />
              )}
            />
          </CardContent>
        </CardActionArea>
        <CardActions disableSpacing>
          <Button
            type="submit"
            disabled={loading || !isDirty}
            size="small"
            color="primary"
          >
            Send request
          </Button>
          {called && !isDirty && (
            <Alert sx={successAlertStyles} severity="success">
              Saved!
            </Alert>
          )}
        </CardActions>
      </Card>
    </form>
  );
};

export { CreateContactCard };
