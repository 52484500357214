import React from 'react';
import { Unstable_Grid2 as Grid, Skeleton, Stack } from '@mui/material';

const containerStyles = { maxWidth: 640, flexGrow: 1 };

const AccountSkeleton = React.memo(() => {
  return (
    <Stack gap={2} sx={containerStyles}>
      <Grid container spacing={2}>
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={2}
        >
          <Skeleton />
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={10}>
          <Skeleton />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={2}
        >
          <Skeleton />
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={10}>
          <Skeleton />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={2}
        >
          <Skeleton />
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={10}>
          <Skeleton />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={2}
        >
          <Skeleton />
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={10}>
          <Skeleton />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={2}
        >
          <Skeleton />
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={10}>
          <Skeleton />
        </Grid>
      </Grid>
    </Stack>
  );
});

export { AccountSkeleton };
