// eslint-disable
import React, { MutableRefObject, useRef } from 'react';
import { MapRef } from 'react-map-gl';

type GoToValue = {
  mapRef: MutableRefObject<MapRef>;
  goTo: (longitude: number, latitude: number) => void;
};

function createCtx(defaultValue: GoToValue) {
  const ctx = React.createContext(defaultValue);

  function GoToProvider(props: React.PropsWithChildren<{}>) {
    const mapRef = useRef(null);

    const goTo = (longitude, latitude) =>
      mapRef.current
        ? mapRef.current.flyTo({ center: [longitude, latitude], duration: 0 })
        : () => {};

    const value = { mapRef, goTo };

    return <ctx.Provider value={value} {...props} />;
  }

  return [ctx, GoToProvider] as [typeof ctx, typeof GoToProvider];
}

const [GoToContext, GoToProvider] = createCtx(null);

export { GoToContext, GoToProvider };
