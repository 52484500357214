import { useRef, useState } from 'react';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Stack,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';

interface FindActionBarProps {
  siteId?: string;
}

const menuStyles = { width: 250 };

const FindActionBar = ({ siteId }: FindActionBarProps) => {
  const anchorMRef = useRef<HTMLButtonElement>(null);
  const [mOpen, setMOpen] = useState(false);
  const navigate = useNavigate();

  const handleClickCreateNew = () => {
    navigate(`/s/${siteId}/finds/create`);
  };

  return (
    <Paper elevation={0}>
      <Stack direction="row" gap={1}>
        <Button type="button" onClick={handleClickCreateNew}>
          Create new
        </Button>
        <IconButton
          ref={anchorMRef}
          onClick={() => setMOpen(!mOpen)}
          aria-label="more"
          aria-haspopup="true"
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          anchorEl={anchorMRef.current}
          onClose={() => setMOpen(false)}
          open={mOpen}
          sx={menuStyles}
        >
          <MenuList dense sx={menuStyles}>
            <MenuItem disabled>Export (coming soon)</MenuItem>
            <MenuItem disabled>Duplicate (coming soon)</MenuItem>
          </MenuList>
        </Menu>
      </Stack>
    </Paper>
  );
};

export { FindActionBar };
