import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import { DialogContent } from './DialogContent';
import { useWorkspace } from '../context/WorkspaceContext/useWorkspace';

const alertStyles = {
  padding: '5px 15px',
  '& > .MuiAlert-icon, & > .MuiAlert-message': {
    padding: 0,
  },
};

const ShareMapDialog = () => {
  const [isCopied, setCopied] = useState(false);
  const {
    store: { shareMapDialogOpen },
    onShareMapDialogClose,
  } = useWorkspace();

  return (
    <Dialog open={shareMapDialogOpen} onClose={onShareMapDialogClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" gap={1}>
          Share this map
          {isCopied && (
            <Alert
              sx={alertStyles}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              Link copied.
            </Alert>
          )}
        </Stack>
      </DialogTitle>
      {shareMapDialogOpen && <DialogContent onCopy={setCopied} />}
      <DialogActions>
        <Button onClick={onShareMapDialogClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export { ShareMapDialog };
