import React from 'react';
import { FormControlLabel, Stack, Switch } from '@mui/material';
import { useSettings } from '../context/SettingsContext/useSettings';

const labelStyles = { fontSize: 14 };

const ObjectToolbox = () => {
  const {
    store: { objectLayerEnabled },
    toggleObjectLayer,
  } = useSettings();

  const handleToggleObjectLayer = () => {
    toggleObjectLayer();
  };

  return (
    <Stack alignItems="flex-start">
      <FormControlLabel
        value="Display objects"
        sx={labelStyles}
        control={
          <Switch
            size="small"
            checked={objectLayerEnabled}
            onChange={handleToggleObjectLayer}
            color="primary"
          />
        }
        label="Object layer"
        labelPlacement="start"
      />
    </Stack>
  );
};

export { ObjectToolbox };
