import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Alert,
  Button,
  Unstable_Grid2 as Grid,
  Input,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useMutation } from '@apollo/client';
import { AUTH_QUERY, UPDATE_ACCOUNT_MUTATION } from '../../gql';

interface UserSettingsFormProps {
  user: {
    name: string;
    role: string;
    updatedAt: string;
    createdAt: string;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    email: string;
  };
}

const containerStyles = { maxWidth: 640, flexGrow: 1 };
const inputStyles = { width: '100%' };
const labelStyles = { fontWeight: 600 };
const submitButtonStyles = {
  borderRadius: 2,
  fontSize: '1rem',
};
const successAlertStyles = { width: '100px' };

const UserSettingsForm = React.memo(({ user }: UserSettingsFormProps) => {
  const [editPassword, setEditPassword] = useState(false);
  const [
    updateAccount,
    { called: calledUpdate, loading: loadingUpdate },
  ] = useMutation(UPDATE_ACCOUNT_MUTATION, {
    refetchQueries: [{ query: AUTH_QUERY }],
  });

  const handleEditPassword = () => {
    setEditPassword(true);
  };

  const defaultValues = user
    ? {
        displayName: user.displayName,
        firstName: user.firstName,
        lastName: user.lastName,
        oldPassword: undefined,
        newPassword: undefined,
        newPasswordConfirm: undefined,
      }
    : {};

  const {
    reset,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data) => {
    await updateAccount({
      variables: {
        user: {
          displayName: data.displayName,
          firstName: data.firstName,
          lastName: data.lastName,
          newPassword: data.newPassword,
          oldPassword: data.oldPassword,
        },
      },
    });

    // add yup validation and error handling and displaying

    reset(data);
  };

  return (
    <Stack
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      autoComplete="off"
      gap={2}
      sx={containerStyles}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={3}
        >
          <Typography sx={labelStyles}>Email</Typography>
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={9}>
          {user.email}
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={3}
        >
          <Typography sx={labelStyles}>Role</Typography>
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={9}>
          {user.role}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={3}
        >
          <InputLabel sx={labelStyles} htmlFor="display-name">
            Display name
          </InputLabel>
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={9}>
          <Controller
            name="displayName"
            control={control}
            render={({ field }) => (
              <Input
                autoComplete="off"
                id="display-name"
                type="text"
                sx={inputStyles}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={3}
        >
          <InputLabel sx={labelStyles} htmlFor="first-name">
            First name (private)
          </InputLabel>
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={9}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <Input
                autoComplete="off"
                id="first-name"
                type="text"
                sx={inputStyles}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={3}
        >
          <InputLabel sx={labelStyles} htmlFor="last-name">
            Last name (private)
          </InputLabel>
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={9}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <Input
                autoComplete="off"
                id="last-name"
                type="text"
                sx={inputStyles}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      {!editPassword && (
        <Grid container spacing={2}>
          <Grid
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            xs={3}
            md={3}
          >
            <InputLabel sx={labelStyles} htmlFor="new-password">
              Password
            </InputLabel>
          </Grid>
          <Grid justifyContent="flex-start" alignItems="center" xs={9} md={9}>
            <Button
              type="button"
              onClick={handleEditPassword}
              variant="text"
              endIcon={<EditIcon fontSize="small" />}
            >
              Edit password
            </Button>
          </Grid>
        </Grid>
      )}
      {editPassword && (
        <Grid container spacing={2}>
          <Grid
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            xs={3}
            md={3}
          >
            <InputLabel sx={labelStyles} htmlFor="new-password">
              Old Password
            </InputLabel>
          </Grid>
          <Grid justifyContent="flex-start" alignItems="center" xs={9} md={9}>
            <Controller
              name="oldPassword"
              control={control}
              render={({ field }) => (
                <Input
                  autoComplete="off"
                  id="old-password"
                  type="password"
                  sx={inputStyles}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
      {editPassword && (
        <Grid container spacing={2}>
          <Grid
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            xs={3}
            md={3}
          >
            <InputLabel sx={labelStyles} htmlFor="new-password">
              New Password
            </InputLabel>
          </Grid>
          <Grid justifyContent="flex-start" alignItems="center" xs={9} md={9}>
            <Controller
              name="newPassword"
              control={control}
              render={({ field }) => (
                <Input
                  autoComplete="off"
                  id="new-password"
                  type="password"
                  sx={inputStyles}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
      {editPassword && (
        <Grid container spacing={2}>
          <Grid
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            xs={3}
            md={3}
          >
            <InputLabel sx={labelStyles} htmlFor="confirm-new-password">
              New password confirm
            </InputLabel>
          </Grid>
          <Grid justifyContent="flex-start" alignItems="center" xs={9} md={9}>
            <Controller
              name="newPasswordConfirm"
              control={control}
              render={({ field }) => (
                <Input
                  id="confirm-new-password"
                  type="password"
                  sx={inputStyles}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid
          mdOffset={3}
          justifyContent="flex-start"
          alignItems="center"
          xs={8}
        >
          <Stack direction="row" gap={1}>
            <Button
              disabled={loadingUpdate || !isDirty}
              type="submit"
              variant="contained"
              sx={submitButtonStyles}
            >
              Save
            </Button>
            {calledUpdate && !isDirty && (
              <Alert sx={successAlertStyles} severity="success">
                Saved!
              </Alert>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
});

export { UserSettingsForm };
