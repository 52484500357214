import React, { useEffect, useRef, useState } from 'react';
import loadable from '@loadable/component';

import { SceneContainer } from '..';
import { GeolocationContext } from '../context';
import { useLayer } from '../LayerContext/useLayer';
import { getItem } from '../../io';
import { useSettings } from '../context/SettingsContext/useSettings';
import { useWorkspace } from '../context/WorkspaceContext/useWorkspace';
import { DrawerPuller } from './DrawerPuller';
import { ShareMapDialog } from '../ShareMapDialog';

const MapControls = loadable(
  () => import('../MapControls').then((mod) => mod.MapControls),
  { ssr: false }
);

const MapView = loadable(
  () => import('../MapView').then((mod) => mod.MapView),
  { ssr: false }
);

const Scene = ({ isMobile = false }: { isMobile?: boolean }) => {
  const { layer } = useLayer();
  const {
    store: {
      activeFindFilter,
      findLayersEnabled,
      heatmapLayerEnabled,
      isadLayerEnabled,
      objectLayerEnabled,
      findColorFunction,
    },
    setSelectedFind,
    toggleMapDrawer,
  } = useSettings();
  const {
    store: { sharedInitialCoordinates, markerPoints },
    onClickMap,
    setSharedMap,
  } = useWorkspace();
  const [geolocate, setGeolocate] = useState(false);

  const sceneContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = sceneContainerRef.current;

    if (container) {
      const resizeObserver = new ResizeObserver(() => {
        window.dispatchEvent(new Event('resize')); // Deck.gl's resize listener
      });

      resizeObserver.observe(container);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const toggleMapDrawerHandle = () => {
    toggleMapDrawer();
    window.dispatchEvent(new Event('resize'));
  };

  const clearInitialization = () =>
    setSharedMap({ sharedInitialCoordinates: null });

  const initialCoordinates =
    typeof window !== 'undefined'
      ? sharedInitialCoordinates || getItem('initialCoordinates')
      : null;

  return (
    <GeolocationContext.Provider value={geolocate}>
      <SceneContainer ref={sceneContainerRef} isMobile={isMobile}>
        <MapControls onGeolocate={setGeolocate} />
        <MapView
          layer={layer}
          filters={activeFindFilter}
          findLayersEnabled={findLayersEnabled}
          heatmapLayerEnabled={heatmapLayerEnabled}
          objectLayerEnabled={objectLayerEnabled}
          findColorFunction={findColorFunction}
          onSelectFind={setSelectedFind}
          isadLayerEnabled={isadLayerEnabled}
          onClickMap={onClickMap}
          markerPoints={markerPoints}
          initialCoordinates={initialCoordinates}
          onLoad={clearInitialization}
        />
        {!isMobile && <DrawerPuller onClick={toggleMapDrawerHandle} />}
      </SceneContainer>
      <ShareMapDialog />
    </GeolocationContext.Provider>
  );
};

export { Scene };
