import { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Alert,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Snackbar,
  Stack,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useLocation, useParams } from 'react-router-dom';

import { ViewModeToggle } from './ViewModeToggle';
import { GENERATE_LABELS_MUTATION } from '../../../gql';

const menuStyles = { width: 250 };
const fullWidthStyles = { width: '100%' };

const SiteActionBar = ({ findListMode, setFindListMode }) => {
  const location = useLocation();
  const { siteId } = useParams();
  const anchorMRef = useRef<HTMLButtonElement>(null);
  const [mOpen, setMOpen] = useState(false);
  const [downloadSnackOpen, setDownloadSnackOpen] = useState(false);
  const isFinds = location.pathname.endsWith('/finds');

  const [generateLabels, { loading: loadingGenerate }] = useMutation(
    GENERATE_LABELS_MUTATION,
    {
      onCompleted: async ({ generateLabels: { __typename, url } }) => {
        setDownloadSnackOpen(false);
        // start download...
        if (!!url) {
          const parts = url.split('/');
          const buffer = await fetch(url, {
            mode: 'cors',
          }).then((response) => response.arrayBuffer());
          const data = Buffer.from(buffer).toString('base64');
          const fileURL = `data:application/pdf;base64,${data}`;

          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = parts[parts.length - 1];
          alink.click();
        }
      },
    }
  );

  const handleGenerateLabelsClick = () => {
    generateLabels({ variables: { id: siteId } });
    setMOpen(false);
    setDownloadSnackOpen(true);
  };

  if (isFinds) {
    return (
      <Paper elevation={0}>
        <Stack direction="row" gap={1} alignItems="center">
          <ViewModeToggle
            setFindListMode={setFindListMode}
            findListMode={findListMode}
          />
          <IconButton
            ref={anchorMRef}
            onClick={() => setMOpen(!mOpen)}
            aria-label="more"
            aria-haspopup="true"
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            anchorEl={anchorMRef.current}
            onClose={() => setMOpen(false)}
            open={mOpen}
            sx={menuStyles}
          >
            <MenuList dense sx={menuStyles}>
              <MenuItem disabled>Export (coming soon)</MenuItem>
              <MenuItem disabled>Generate report (coming soon)</MenuItem>
              <MenuItem onClick={handleGenerateLabelsClick}>
                Generate labels
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
        <Snackbar open={downloadSnackOpen}>
          <Alert severity="info" sx={fullWidthStyles}>
            Your download is in progress...
          </Alert>
        </Snackbar>
      </Paper>
    );
  }

  return null;
};

export { SiteActionBar };
