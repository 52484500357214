import { gql } from '@apollo/client';

export const UPDATE_FIND_MUTATION = gql`
  mutation UpdateFindMutation($id: String!, $find: FindInput!) {
    updateFind(id: $id, find: $find) {
      ... on UpdateFindSuccess {
        find {
          id
          createdAt
          description
          updatedAt
          foundAt
          material
          era
          type
          note
          latitude
          longitude
          shared
          visibility
        }
      }
      ... on ValidationError {
        errors {
          error
          path
        }
      }
    }
  }
`;
