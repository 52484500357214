import React from 'react';
import Helmet from 'react-helmet';
import { Container, Stack } from '@mui/material';
import { useQuery } from '@apollo/client';

import { Breadcrumbs, Header, Main } from '../../components';
import { GET_MY_SITES_QUERY } from '../../gql';
import SiteGrid from './SiteGrid';

const Sites = React.memo(() => {
  const { data, loading } = useQuery(GET_MY_SITES_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const rows = data?.getMySites || [];

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Helmet title="Sites | Acolyte"></Helmet>
      <Header />
      <Container maxWidth="xl">
        <Stack direction="row">
          <Stack flexGrow={1} gap={1}>
            <Breadcrumbs />
            <Main>
              <SiteGrid loading={loading} rows={rows} />
            </Main>
          </Stack>
        </Stack>
      </Container>
    </div>
  );

  /*
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Helmet title="Sites | Acolyte"></Helmet>
      <Header />
      <Container maxWidth="xl">
        <Typography fontWeight="bold" variant="h4" gutterBottom>
          My Sites
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeadStyles}>Site name</TableCell>
                <TableCell sx={tableHeadStyles}>Site type</TableCell>
                <TableCell align="right" sx={tableHeadStyles}>
                  Created
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name} <i>{row.shared && '(shared)'}</i>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.siteType}
                  </TableCell>
                  <TableCell align="right">
                    {DateTime.fromISO(row.createdAt).toFormat(
                      'dd.LL.yyyy HH:MM'
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
  */
});

export { Sites };
