import React from 'react';
import { Stack } from '@mui/material';
import { useWorkspace } from '../context/WorkspaceContext/useWorkspace';
import { useSettings } from '../context/SettingsContext/useSettings';
import { Filters } from './Filters';
import { FindLayersSettings } from './FindLayersSettings';
import { FindList } from './FindList';
import { Toolchain } from './Toolchain';
import { MarkerPointList } from './MarkerPointList';

const FindToolbox = () => {
  const {
    store: { activeFindFilter, selectedFind },
    setSelectedFind,
  } = useSettings();
  const {
    store: { markerPoints },
    clearMarkerPoints,
    deleteMarkerPoint,
  } = useWorkspace();

  return (
    <Stack position="relative" gap={1}>
      <Toolchain />
      <FindLayersSettings />
      <Filters key={activeFindFilter ? activeFindFilter.id : 'empty'} />
      {!!selectedFind && (
        <FindList onSelectFind={setSelectedFind} selectedFind={selectedFind} />
      )}
      {!!markerPoints && markerPoints.length > 0 && (
        <MarkerPointList
          onMarkerPointsClear={clearMarkerPoints}
          onMarkerPointDelete={deleteMarkerPoint}
          markerPoints={markerPoints}
        />
      )}
    </Stack>
  );
};

export { FindToolbox };
