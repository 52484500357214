import {
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import RoomIcon from '@mui/icons-material/Room';
import IosShareIcon from '@mui/icons-material/IosShare';
import { MapTools } from '../../context/WorkspaceContext';
import { useWorkspace } from '../../context/WorkspaceContext/useWorkspace';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    height: '30px',
    width: '26px',
  },
}));

const Toolchain = () => {
  const {
    store: { activeMapTool },
    enableDefaultTool,
    enableMarkerPointTool,
    onShareMap,
  } = useWorkspace();

  return (
    <StyledToggleButtonGroup
      value={activeMapTool}
      size="small"
      exclusive
      aria-label="Map toolbox"
    >
      <ToggleButton
        title="Default map cursor"
        value={null}
        key={null}
        onClick={enableDefaultTool}
      >
        <PanToolAltIcon fontSize="small" />
      </ToggleButton>
      <ToggleButton
        title="Add marker point tool"
        value={MapTools.ADD_MARKER_POINT_TOOL}
        key={MapTools.ADD_MARKER_POINT_TOOL}
        onClick={enableMarkerPointTool}
      >
        <RoomIcon fontSize="small" />
      </ToggleButton>
      <ToggleButton
        value="share-map"
        title="Share map"
        key="share-map"
        onClick={onShareMap}
      >
        <IosShareIcon fontSize="small" />
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};

export { Toolchain };
