import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const resetStyles = `
body,
html,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Figtree', sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`;

export const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 24,
          boxShadow: 'none',
          fontWeight: 600,
          textTransform: 'none',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingTop: 1,
          paddingBottom: 1,
          ".MuiOutlinedInput-notchedOutline": {
            borderRadius: 2,
            borderColor:  grey[600],
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor:  grey[600],
          },
          "&:focus .MuiOutlinedInput-notchedOutline": {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor:  grey[600],
          }
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          padding: '8px 12px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: 2,
          borderColor: grey[600],
        },
      },
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: grey[800],
        },
      },
    },
  },
  palette: {},
  typography: {
    fontFamily: "'Figtree', sans-serif",
  },
});
