import { gql } from '@apollo/client';

export const GetCloudflareDirectLinkQueryName = 'GetCloudflareDirectLink';

export const GET_CLOUDFLARE_DIRECT_LINK_QUERY = gql`
  query ${GetCloudflareDirectLinkQueryName}($name: String!) {
    getCloudflareDirectLink(name: $name) {
      id
      uploadURL
    }
  }
`;
