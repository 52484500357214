import React from 'react';
import { GoToContext } from '../GoToContext';

function useGoTo() {
  const context = React.useContext(GoToContext);

  if (!context) {
    throw new Error(`useGoTo must be used within a GoToProvider`);
  }

  const { mapRef, goTo } = context;

  return {
    mapRef,
    goTo,
  };
}

export { useGoTo };
