import Decimal from 'decimal.js';

const pi = new Decimal(Math.PI);
const m = new Decimal(-3.543e-6);
const wx = new Decimal(4.99821).div(3600).times(pi).div(180);
const wy = new Decimal(1.58676).div(3600).times(pi).div(180);
const wz = new Decimal(5.2611).div(3600).times(pi).div(180);
const dx = new Decimal(-570.69);
const dy = new Decimal(-85.69);
const dz = new Decimal(-462.84);
const one = new Decimal(1);
const d2r = pi.div(180);

export function wgs84ToJtskPrecise(
  latitude: number,
  longitude: number,
  height = 0
) {
  const lat = new Decimal(latitude);
  const lng = new Decimal(longitude);
  let a = new Decimal(6378137.0);
  let f1 = new Decimal(298.257223563);

  let B = lat.times(d2r);
  let L = lng.times(d2r);
  let H = new Decimal(height);

  let e2 = one.minus(one.minus(one.div(f1)).pow(2));
  let rho = a.div(one.minus(e2.times(B.sin().pow(2))).sqrt());
  let x1 = rho.plus(H).times(B.cos()).times(L.cos());
  let y1 = rho.plus(H).times(B.cos()).times(L.sin());
  let z1 = one.minus(e2).times(rho).plus(H).times(B.sin());

  let x2 = dx.plus(
    one.plus(m).times(wz.times(y1).minus(wy.times(z1)).plus(x1))
  );
  let y2 = dy.plus(
    one.plus(m).times(wz.neg().times(x1).plus(y1).plus(wx.times(z1)))
  );
  let z2 = dz.plus(
    one.plus(m).times(wy.times(x1).minus(wx.times(y1)).plus(z1))
  );

  a = new Decimal(6377397.15508);
  f1 = new Decimal(299.152812853);
  const ab = f1.div(f1.minus(1));
  const p = x2.pow(2).plus(y2.pow(2)).sqrt();
  e2 = one.minus(one.minus(one.div(f1)).pow(2));
  const th = z2.times(ab).div(p).atan();
  const st = th.sin();
  const ct = th.cos();
  let t = z2
    .plus(e2.times(ab).times(a).times(st.pow(3)))
    .div(p.minus(e2.times(a).times(ct.pow(3))));

  B = t.atan();
  H = one
    .plus(t.pow(2))
    .sqrt()
    .times(p.minus(a.div(one.plus(one.minus(e2).times(t.pow(2))).sqrt())));
  L = y2.div(p.plus(x2)).atan().times(2);

  a = new Decimal(6377397.15508);
  const e = new Decimal(0.081696831215303);
  const n = new Decimal(0.97992470462083);
  const rho0 = new Decimal(12310230.12797036);
  const sinUQ = new Decimal(0.863499969506341);
  const cosUQ = new Decimal(0.504348889819882);
  const sinVQ = new Decimal(0.420215144586493);
  const cosVQ = new Decimal(0.907424504992097);
  const alpha = new Decimal(1.000597498371542);
  const k2 = new Decimal(1.00685001861538);

  let sinB = B.sin();
  t = one.minus(e.times(sinB)).div(one.plus(e.times(sinB)));
  t = one
    .plus(sinB)
    .pow(2)
    .div(one.minus(sinB.pow(2)))
    .times(e.times(t.ln()).exp());

  t = k2.times(alpha.times(t.ln()).exp());

  const sinU = t.minus(1).div(t.plus(1));
  const cosU = one.minus(sinU.pow(2)).sqrt();
  const V = alpha.times(L);
  const sinV = V.sin();
  const cosV = V.cos();
  const cosDV = cosVQ.times(cosV).plus(sinVQ.times(sinV));
  const sinDV = sinVQ.times(cosV).minus(cosVQ.times(sinV));
  const sinS = sinUQ.times(sinU).plus(cosUQ.times(cosU).times(cosDV));
  const cosS = one.minus(sinS.times(sinS)).sqrt();
  const sinD = sinDV.times(cosU).div(cosS);
  const cosD = one.minus(sinD.pow(2)).sqrt();

  const eps = n.times(sinD.div(cosD).atan());
  rho = rho0.times(n.neg().times(one.plus(sinS).div(cosS).ln()).exp());
  const x = rho.times(eps.sin());
  const y = rho.times(eps.cos());

  return { x: x.toString(), y: y.toString() };
}
