import { Button, Paper, Typography } from '@mui/material';
import React from 'react';

const emptyStateStyles = { width: '100%', padding: 2, textAlign: 'center' };

const NoContacts = ({ action = null, children }) => (
  <Paper sx={emptyStateStyles} elevation={1}>
    <Typography variant="body2" color="text.secondary" textAlign="center">
      {children}
    </Typography>
    {action && <Button onClick={action.onClick}>{action.label}</Button>}
  </Paper>
);

export { NoContacts };
