import React from 'react';
import { useQuery } from '@apollo/client';

import { FindData } from '../../pages/Find/types';
import { ImageMasonry } from './ImageMasonry';

// cloudflare api token 1e_LnO8Iwr-tRkTcAsUuzOoM4DOialrScPXHMszv

const FindImages = React.memo(
  ({ called, loading, find, findId, forSite }: FindData) => {
    if (loading) {
      return <div>...loading</div>;
    }

    return (
      <>
        {called && !loading && !find && <div>Not found.</div>}
        {called && find && find?.amcrData?.images && (
          <ImageMasonry images={find?.amcrData?.images} />
        )}
      </>
    );
  }
);

export { FindImages };
