import React from 'react';
import { useMutation } from '@apollo/client';
import loadable from '@loadable/component';
import Helmet from 'react-helmet';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

import { AuthQueryName, SIGN_OUT_MUTATION } from '../../gql';
import { useLoadShared } from '../../hooks';
import {
  FullScreenLoader,
  MapviewDrawer,
  WelcomeScreen,
} from '../../components';
import { useWorkspace } from '../../components/context/WorkspaceContext/useWorkspace';

const Scene = loadable(
  () => import('../../components/Scene').then((mod) => mod.Scene),
  { ssr: false }
);

interface HomeProps {
  isSignedIn: boolean;
}

const Home = ({ isSignedIn = false }: HomeProps) => {
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const [signOut] = useMutation(SIGN_OUT_MUTATION, {
    refetchQueries: [AuthQueryName],
  });

  const { setSharedMap } = useWorkspace();
  const { sharedMapLoading } = useLoadShared({ setSharedMap, isSignedIn });

  if (!isSignedIn) {
    return <WelcomeScreen />;
  }

  if (sharedMapLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Stack direction="row" height="100%">
      <Helmet title="Acolyte"></Helmet>
      <Scene isMobile={mobileBreakpoint} />
      <MapviewDrawer isMobile={mobileBreakpoint} onSignOut={signOut} />
    </Stack>
  );
};
export { Home };
