import React from 'react';
import { Skeleton, Stack } from '@mui/material';

const SiteSkeleton = React.memo(() => {
  return (
    <Stack>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Stack>
  );
});

export { SiteSkeleton };
