import { gql } from '@apollo/client';

export const GetSiteCollaboratorsQueryName = 'GetSiteCollaborators';

export const GET_SITE_COLLABORATORS_QUERY = gql`
  query ${GetSiteCollaboratorsQueryName}($siteId: String!, $pagination: Pagination) {
    getSiteCollaborators(siteId: $siteId, pagination: $pagination) {
      id
      email
      displayName
      level
      createdAt
      userId
    }
  }
`;
