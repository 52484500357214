import { gql } from '@apollo/client';

export const GetMyObjectsQueryName = 'GetMyObjects';

export const GET_MY_OBJECTS_QUERY = gql`
  query ${GetMyObjectsQueryName} {
    getMyObjects {
      id
      createdAt
      updatedAt
      name
      position
      type
      visibility
    }
  }
`;
