import { gql } from '@apollo/client';

export const ACCEPT_CONTACT_MUTATION = gql`
  mutation AcceptContactRequestMutation($id: String!) {
    acceptContactRequest(id: $id) {
      ... on AcceptContactSuccess {
        contact {
          createdAt
          id
          responderUser
          email
          displayName
        }
      }
      ... on ValidationError {
        errors {
          error
          path
        }
      }
    }
  }
`;
