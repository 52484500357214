import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';

interface DrawerPullerProps {
  onClick: (e: MouseEvent) => void;
}

const DrawerPuller = styled((props: BoxProps & DrawerPullerProps) => (
  <Box alignItems="center" justifyContent="center" {...props} />
))<BoxProps>(({ theme }) => ({
  '&:before': {
    content: "'>'",
  },
  '&:hover': {
    color: blueGrey[700],
  },
  cursor: 'pointer',
  color: grey[500],
  display: 'inline-flex',
  width: '20px',
  height: '40px',
  right: 0,
  top: 'calc(50% - 20px)',
  position: 'absolute',
  zIndex: 3,
  speak: 'none',
  fontWeight: 500,
  textAlign: 'center',
  background: '#fff',
  border: 0,
  borderRadius: '5px 0 0 5px',
}));

export { DrawerPuller };
