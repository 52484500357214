import React, { useMemo, useState } from 'react';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Lightbox from 'yet-another-react-lightbox';
import PhotoAlbum, { RenderRowContainerProps } from 'react-photo-album';

const animationSettings = { fade: 150, swipe: 0 };
const noImagesIconStyles = { mx: 1, verticalAlign: 'text-top' };

const renderPhoto = ({
  photo,
  imageProps: { alt, style, ...restImageProps },
  layoutOptions,
}) => (
  <img
    alt={alt}
    style={{ ...style, objectFit: 'cover', maxHeight: 150, maxWidth: 150 }}
    {...restImageProps}
  />
);

const renderRowContainer = ({
  rowContainerProps,
  children,
}: RenderRowContainerProps) => (
  <div
    {...rowContainerProps}
    style={{
      ...(rowContainerProps?.style || {}),
      justifyContent: 'flex-start',
    }}
  >
    {children}
  </div>
);

const ImageList = ({ images, amcrImages, mainImage }) => {
  const [index, setIndex] = useState(-1);

  const [slides, thumbs] = useMemo(
    () => [
      [
        ...(images || []).map((i, index) => ({
          src: `https://imagedelivery.net/3zdDZwDRXumMqC2LPbSSXw/${i}/public`,
        })),
        ...(amcrImages || []).map((i, index) => ({
          src: `https://digiarchiv.aiscr.cz/api/img/medium?size=medium&id=${i}`,
        })),
      ],
      [
        ...(images || []).map((i, index) => ({
          src: `https://imagedelivery.net/3zdDZwDRXumMqC2LPbSSXw/${i}/w=150`,
          key: i,
          width: 150,
          height: 150,
          meta: {
            index,
          },
        })),
        ...(amcrImages || []).map((i, index) => ({
          src: `https://digiarchiv.aiscr.cz/api/img/thumb?id=${i}`,
          key: i,
          width: 150,
          height: 150,
          meta: {
            index: index + (images || []).length,
          },
        })),
      ],
    ],
    [images, amcrImages]
  );

  const handleThumbnailClick = ({ index }) => {
    setIndex(index);
  };

  return slides.length === 0 ? (
    <Typography my={2} textAlign="center" color={grey[600]}>
      <TipsAndUpdatesIcon sx={noImagesIconStyles} />
      No images uploaded yet.
    </Typography>
  ) : (
    <>
      <PhotoAlbum
        targetRowHeight={150}
        padding={5}
        spacing={5}
        layout="rows"
        photos={thumbs}
        onClick={handleThumbnailClick}
        renderPhoto={renderPhoto}
        renderRowContainer={renderRowContainer}
      />
      <Lightbox
        animation={animationSettings}
        index={index}
        slides={slides}
        open={index >= 0}
        close={() => setIndex(-1)}
      />
    </>
  );
};

export { ImageList };
