import React from 'react';
import { LayerContext } from '../LayerContext';

function useLayer() {
  const context = React.useContext(LayerContext);

  if (!context) {
    throw new Error(`useLayer must be used within a LayerProvider`);
  }

  const { layer, setLayer } = context;

  const clearLayer = () => setLayer(null);

  return {
    layer,
    setLayer,
    clearLayer,
  };
}

export { useLayer };
