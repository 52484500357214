import { useQuery } from '@apollo/client';
import React, { SyntheticEvent, useMemo } from 'react';
import { Box, Chip, Stack } from '@mui/material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import {
  GET_ALL_FILTER_TYPES_QUERY,
  GET_MY_SITES_QUERY,
} from '../../../../gql';
import { FilterInput } from '../../../FilterInput';
import { useSettings } from '../../../context/SettingsContext/useSettings';

const FilterBuilder = ({
  showEraFilter = false,
  showFindTypeFilter = false,
  showGroupFilter = false,
  showMaterialFilter = false,
  showSiteFilter = false,
  showTagFilter = false,
}) => {
  const {
    data: availableFiltersData,
    loading: availableFiltersLoading,
  } = useQuery(GET_ALL_FILTER_TYPES_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const { data: sitesData, loading: sitesLoading } = useQuery(
    GET_MY_SITES_QUERY,
    {
      fetchPolicy: 'cache-first',
    }
  );

  const {
    disableFindsFilter,
    updateFindsFilter,
    store: { activeFindFilter },
  } = useSettings();

  const eraOptions = useMemo(() => {
    const rawOptions = availableFiltersData?.getAllFilterTypes?.eras || [];
    return rawOptions
      .filter((i) => !i.parentId)
      .sort((a, b) => a.ordinal - b.ordinal)
      .map((i) => [i, ...rawOptions.filter((o) => o.parentId === i.id)])
      .flat();
  }, [availableFiltersData?.getAllFilterTypes?.eras]);

  const findTypeOptions = useMemo(() => {
    const rawOptions = availableFiltersData?.getAllFilterTypes?.findTypes || [];
    return rawOptions
      .reduce(
        (acc, type) =>
          acc.includes(type.category) ? acc : [...acc, type.category],
        []
      )
      .sort((a, b) => a - b)
      .map((i) => [
        { id: i, category: null },
        ...rawOptions.filter((o) => o.category === i),
      ])
      .flat();
  }, [availableFiltersData?.getAllFilterTypes?.findTypes]);

  const materialsOptions = useMemo(
    () =>
      (availableFiltersData?.getAllFilterTypes?.materials || [])
        .toSorted((a, b) => a - b)
        .map((m) => ({ id: m })),
    [availableFiltersData?.getAllFilterTypes?.materials]
  );

  const tagsOptions = useMemo(
    () =>
      (availableFiltersData?.getAllFilterTypes?.tags || [])
        .toSorted((a, b) => a.name - b.name)
        .map((m) => ({ id: m.name })),
    [availableFiltersData?.getAllFilterTypes?.tags]
  );

  const groupsOptions = useMemo(
    () =>
      (availableFiltersData?.getAllFilterTypes?.groups || []).toSorted(
        (a, b) => a.name - b.name
      ),
    [availableFiltersData?.getAllFilterTypes?.groups]
  );

  const defaultEras = activeFindFilter.eras
    ? eraOptions.filter((o) => activeFindFilter.eras.includes(o.id))
    : [];

  const defaultSites = activeFindFilter.sites
    ? (sitesData?.getMySites || []).filter((o) =>
        activeFindFilter.sites.includes(o.id)
      )
    : [];

  const defaultFindTypes = activeFindFilter.findTypes
    ? findTypeOptions.filter((o) => activeFindFilter.findTypes.includes(o.id))
    : [];

  const defaultMaterials = activeFindFilter.materials
    ? materialsOptions.filter((o) => activeFindFilter.materials.includes(o.id))
    : [];

  const defaultTags = activeFindFilter.tags
    ? tagsOptions.filter((o) => activeFindFilter.tags.includes(o.id))
    : [];

  const defaultGroups = activeFindFilter.groups
    ? groupsOptions.filter((o) => activeFindFilter.groups.includes(o.id))
    : [];

  const handleErasChange = (e: SyntheticEvent<Element, Event>, value: any) => {
    const updatedEras = value.map((e) => e.id);

    if (updatedEras.length) {
      updateFindsFilter({ ...activeFindFilter, eras: updatedEras });
    } else {
      const { eras, ...rest } = activeFindFilter;
      updateFindsFilter(rest);
    }
  };

  const handleSitesChange = (e: SyntheticEvent<Element, Event>, value: any) => {
    const updatedSites = value.map((s) => s.id);

    if (updatedSites.length) {
      updateFindsFilter({ ...activeFindFilter, sites: updatedSites });
    } else {
      const { sites, ...rest } = activeFindFilter;
      updateFindsFilter(rest);
    }
  };

  const handleFindTypesChange = (
    e: SyntheticEvent<Element, Event>,
    value: any
  ) => {
    const updatedFindTypes = value.map((s) => s.id);

    if (updatedFindTypes.length) {
      updateFindsFilter({ ...activeFindFilter, findTypes: updatedFindTypes });
    } else {
      const { findTypes, ...rest } = activeFindFilter;
      updateFindsFilter(rest);
    }
  };

  const handleMaterialsChange = (
    e: SyntheticEvent<Element, Event>,
    value: any
  ) => {
    const updatedMaterials = value.map((s) => s.id);

    if (updatedMaterials.length) {
      updateFindsFilter({ ...activeFindFilter, materials: updatedMaterials });
    } else {
      const { materials, ...rest } = activeFindFilter;
      updateFindsFilter(rest);
    }
  };

  const handleTagsChange = (e: SyntheticEvent<Element, Event>, value: any) => {
    const updatedTags = value.map((s) => s.id);

    if (updatedTags.length) {
      updateFindsFilter({ ...activeFindFilter, tags: updatedTags });
    } else {
      const { tags, ...rest } = activeFindFilter;
      updateFindsFilter(rest);
    }
  };

  const handleGroupsChange = (
    e: SyntheticEvent<Element, Event>,
    value: any
  ) => {
    const updatedGroups = value.map((s) => s.id);

    if (updatedGroups.length) {
      updateFindsFilter({ ...activeFindFilter, groups: updatedGroups });
    } else {
      const { groups, ...rest } = activeFindFilter;
      updateFindsFilter(rest);
    }
  };

  if (availableFiltersLoading || sitesLoading) {
    return <div>...loading</div>;
  }

  return (
    <Stack sx={{ margin: '20px 0' }} gap={1}>
      {showEraFilter && (
        <FilterInput
          onChange={handleErasChange}
          parent="parentId"
          label="Filter by era"
          options={eraOptions}
          val={defaultEras}
          key={`${activeFindFilter.id}-eras`}
        />
      )}
      {showSiteFilter && (
        <FilterInput
          onChange={handleSitesChange}
          label="Filter by site"
          optionLabelProperty="name"
          options={sitesData?.getMySites || []}
          key={`${activeFindFilter.id}-sites`}
          val={defaultSites}
        />
      )}
      {showFindTypeFilter && (
        <FilterInput
          onChange={handleFindTypesChange}
          parent="category"
          label="Filter by find type"
          options={findTypeOptions}
          key={`${activeFindFilter.id}-find-types`}
          val={defaultFindTypes}
        />
      )}
      {showMaterialFilter && (
        <FilterInput
          onChange={handleMaterialsChange}
          label="Filter by material"
          options={materialsOptions}
          key={`${activeFindFilter.id}-materials`}
          val={defaultMaterials}
        />
      )}
      {showGroupFilter && (
        <FilterInput
          onChange={handleGroupsChange}
          label="Filter by group"
          options={groupsOptions}
          optionLabelProperty="name"
          key={`${activeFindFilter.id}-groups`}
          val={defaultGroups}
        />
      )}
      {showTagFilter && (
        <FilterInput
          onChange={handleTagsChange}
          label="Filter by tag"
          options={tagsOptions}
          key={`${activeFindFilter.id}-tags`}
          val={defaultTags}
        />
      )}
      <Box py={2}>
        <Chip
          icon={<FilterAltOffIcon />}
          size="small"
          label="Remove filter"
          variant="outlined"
          onClick={disableFindsFilter}
        />
      </Box>
    </Stack>
  );
};

export { FilterBuilder };
