import { gql } from '@apollo/client';

export const UPDATE_SITE_MUTATION = gql`
  mutation UpdateSiteMutation($id: String!, $site: SiteInput!) {
    updateSite(id: $id, site: $site) {
      ... on UpdateSiteSuccess {
        site {
          id
          createdAt
          createdBy
          description
          eraId
          localName
          name
          longitude
          latitude
          shared
          siteType
          visibility
          updatedAt
        }
      }
      ... on ValidationError {
        errors {
          error
          path
        }
      }
    }
  }
`;
