import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Avatar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { GET_MY_CONTACTS_QUERY } from '../../gql';

const avatarStyles = { width: 32, height: 32 };
const menuStyles = { width: 250 };

const UserMenuButton = ({ onLogoutClick }) => {
  const { data: contactsData, loading } = useQuery(GET_MY_CONTACTS_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const anchorMRef = useRef<HTMLButtonElement>(null);
  const [mOpen, setMOpen] = useState(false);

  return (
    <>
      <IconButton
        ref={anchorMRef}
        onClick={() => setMOpen(!mOpen)}
        size="small"
        aria-haspopup="true"
      >
        <Avatar sx={avatarStyles} />
      </IconButton>
      <Menu
        anchorEl={anchorMRef.current}
        onClose={() => setMOpen(false)}
        open={mOpen}
        sx={menuStyles}
      >
        <MenuList dense sx={menuStyles}>
          <MenuItem component={Link} to="/account">
            Account
          </MenuItem>
          <MenuItem component={Link} to="/contacts">
            Contacts
          </MenuItem>
          <MenuItem component={Link} to="/s">
            Sites
          </MenuItem>
          <Divider />
          <MenuItem onClick={onLogoutClick}>Log out</MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export { UserMenuButton };
