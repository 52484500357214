interface SharedMapSettings {
  longitude: number;
  latitude: number;
  zoom: number;
}

const parseInitialSharedCoords = (mapSettings: SharedMapSettings = null) => {
  if (!mapSettings) {
    return {
      initialCoords: null,
      latitude: null,
      longitude: null,
      zoom: null,
    };
  }
  return {
    initialCoords: `${mapSettings.longitude},${mapSettings.latitude},${mapSettings.zoom}`,
    latitude: mapSettings.latitude,
    longitude: mapSettings.longitude,
    zoom: mapSettings.zoom,
  };
};

export { parseInitialSharedCoords };
