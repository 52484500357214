import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack, Tab, TabProps, Tabs, TabsProps } from '@mui/material';
import BallotIcon from '@mui/icons-material/Ballot';
import FlagIcon from '@mui/icons-material/Flag';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import { UserMenuButton } from '../../UserMenuButton';
import { FindToolbox } from '../../FindToolbox';
import { ObjectToolbox } from '../../ObjectToolbox';
import { SiteList } from '../../SiteList';
import { useSettings } from '../../context/SettingsContext/useSettings';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const StyledTabs = styled((props: TabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ style: { display: 'none' } }} />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    minHeight: '48px',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    color: '#010101',
    '&.Mui-selected': {
      color: '#000',
      background: '#efefef',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  })
);

const DrawerContent = ({ handleLogoutClick }) => {
  const {
    store: { mainDrawerIndex },
    setMainDrawerIndex,
  } = useSettings();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setMainDrawerIndex(newValue);
  };

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <StyledTabs
          value={mainDrawerIndex}
          onChange={handleChange}
          aria-label="map drawer tabs"
        >
          <StyledTab
            iconPosition="start"
            icon={<BallotIcon />}
            label="Finds"
            aria-label="finds"
          />
          <StyledTab
            iconPosition="start"
            icon={<FlagIcon />}
            label="Sites"
            aria-label="sites"
          />
          <StyledTab
            iconPosition="start"
            icon={<BubbleChartIcon />}
            label="Objects"
            aria-label="objects"
          />
        </StyledTabs>
        <Box mx={2}>
          <UserMenuButton onLogoutClick={handleLogoutClick} />
        </Box>
      </Stack>
      <Box px={3} py={1}>
        <TabPanel value={mainDrawerIndex} index={0}>
          <FindToolbox />
        </TabPanel>
        <TabPanel value={mainDrawerIndex} index={1}>
          <SiteList />
        </TabPanel>
        <TabPanel value={mainDrawerIndex} index={2}>
          <ObjectToolbox />
        </TabPanel>
      </Box>
    </Stack>
  );
};

export { DrawerContent };
