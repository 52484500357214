import React from 'react';
import { Link as BaseLink, Box, Button, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextField as SearchField,
} from 'material-react-table';
import { Link } from 'react-router-dom';
import { Find } from './types';

const columns: MRT_ColumnDef<Find>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    size: 100,
    Cell: ({ renderedCellValue, row, cell }) => (
      <BaseLink
        underline="none"
        component={Link}
        to={`/f/${cell.getValue()}/edit`}
      >
        <span>{renderedCellValue}</span>
      </BaseLink>
    ),
  },
  {
    accessorKey: 'evId',
    header: 'EvID',
    size: 50,
  },
  {
    accessorKey: 'type',
    header: 'Find type',
    size: 180,
  },
  {
    accessorKey: 'material',
    header: 'Material',
    size: 120,
  },
  {
    accessorKey: 'era',
    header: 'Era',
    size: 180,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    size: 220,
  },
  {
    Cell: ({ cell }) =>
      cell.getValue<Date | null>()
        ? cell.getValue<Date>()?.toLocaleDateString()
        : '-',
    accessorFn: (row) => row.foundAt && new Date(row.foundAt),
    header: 'Date found',
    size: 120,
  },
];

const gridStyles = { marginBottom: 6, display: 'grid' };

export default function FindGrid({ rows, loading = false }) {
  const table = useMaterialReactTable({
    columns,
    data: rows,
    enableRowSelection: true,
    enableToolbarInternalActions: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    initialState: {
      pagination: { pageSize: 50, pageIndex: 0 },
      density: 'compact',
      showGlobalFilter: true,
    },
    state: { isLoading: loading },
    muiPaginationProps: {
      showRowsPerPage: false,
    },
    muiSearchTextFieldProps: ({ table }) => ({
      size: 'small',
      sx: {
        width: '18ch',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#E0E3E7',
          },
          '&:hover fieldset': {
            borderColor: '#B2BAC2',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#6F7E8C',
            borderWidth: '1px',
          },
        },
        '& .MuiOutlinedInput-input': {
          height: '1.2em',
        },
      },
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon onClick={() => table.resetGlobalFilter()} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {!!table.getState().globalFilter ? (
              <ClearIcon
                cursor="pointer"
                onClick={() => table.resetGlobalFilter()}
              />
            ) : (
              <ClearIcon htmlColor="transparent" />
            )}
          </InputAdornment>
        ),
      },
    }),
    muiTablePaperProps: {
      sx: gridStyles,
    },
    renderTopToolbar: ({ table }) => (
      <Box sx={{ display: 'flex', gap: '1rem', p: 1.5 }}>
        <Button
          component={Link}
          to="create"
          color="primary"
          variant="contained"
        >
          Create Find
        </Button>
        <SearchField table={table} />
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
}
