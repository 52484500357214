import React, { SyntheticEvent } from 'react';
import {
  AutocompleteGetTagProps,
  useAutocomplete,
} from '@mui/base/useAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';

interface FilterInputProps {
  parent?: string;
  defaultValue?: any[];
  val?: any[];
  label: string;
  optionLabelProperty?: string;
  options: OptionType[];
  onChange: (e: SyntheticEvent<Element, Event>, value: any) => void;
  ref?: React.RefObject<HTMLInputElement>;
}

interface OptionType {
  id: string;
}

const Label = styled('label')`
  padding: 0 0 2px;
  line-height: 1.5;
  font-size: 12px;
  display: block;
`;

const InputWrapper = styled('div')(
  ({ theme }) => `
  font-size: 14px;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,0.65)'
        : 'rgba(0,0,0,.85)'
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

interface ListItemProps {
  $isParent: boolean;
  $isParentDefined: boolean;
}

const getEnrichedOptionProps = (
  getProps,
  option,
  options,
  index,
  currentValue,
  optionLabelProperty,
  parent
) => {
  if (
    !currentValue.length ||
    !parent ||
    !option[parent] ||
    !currentValue.filter((o) => !o[parent]).length ||
    !currentValue.filter((o) => o[optionLabelProperty] === option[parent])
      .length
  ) {
    return getProps({ option, index });
  }

  const parentIndex = options.findIndex(
    (o) => o[optionLabelProperty] === option[parent]
  );
  const { id, key, onMouseMove, ...parentProps } = getProps({
    option: options[parentIndex],
    index: parentIndex,
  });

  return { ...getProps({ option, index }), ...parentProps };
};

function Tag(props: TagProps) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
  };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const Listbox = styled('ul')(
  ({ theme }) => `
  width: 100%;
  font-size: 14px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    cursor: pointer;
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

const ListItem = styled('li')<ListItemProps>`
  font-weight: ${(props) =>
    props.$isParent && props.$isParentDefined ? 'bold' : 'normal'};
  padding: ${(props) =>
    props.$isParent || !props.$isParentDefined ? '5px 12px' : '5px 18px'};
`;

const FilterInput = ({
  defaultValue,
  val,
  parent,
  label,
  optionLabelProperty = 'id',
  options,
  onChange,
}: FilterInputProps) => {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    defaultValue,
    value: val,
    id: 'filter-builder-input',
    multiple: true,
    onChange,
    options,
    getOptionLabel: (option: OptionType) => option[optionLabelProperty],
  });

  return (
    <div style={{ position: 'relative' }}>
      <div {...getRootProps()}>
        <Label {...getInputLabelProps()}>{label}</Label>
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {value.map((option: OptionType, index: number) => (
            <StyledTag
              label={option[optionLabelProperty]}
              {...getTagProps({ index })}
            />
          ))}
          <input {...getInputProps()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as typeof options).map((option, index) => (
            <ListItem
              $isParentDefined={!!parent}
              $isParent={option[parent] === null}
              {...getEnrichedOptionProps(
                getOptionProps,
                option,
                options,
                index,
                value,
                optionLabelProperty,
                parent
              )}
            >
              <span>{option[optionLabelProperty]}</span>
              <CheckIcon fontSize="small" />
            </ListItem>
          ))}
        </Listbox>
      ) : null}
    </div>
  );
};

export { FilterInput };
