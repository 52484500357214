
import Decimal from 'decimal.js';

const pi = new Decimal(Math.PI);

export function wgs84ToWebMercator(lon: number, lat: number){
  const longitude = new Decimal(lon);
  const latitude  = new Decimal(lat);
  const earthRad = new Decimal(6378137.0);

  const x = longitude.times(pi.div(180)).times(earthRad);
  const a = latitude.times(pi.div(180));
  const y = earthRad.div(2).times(a.sin().plus(1).div(a.sin().minus(1).ln()));
  return { x, y };
}


