import { Container, ContainerProps, IconButton, Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { LogoIcon } from '../Icons';

const logoStyles = { fontSize: 32 };

const Header = ({
  maxWidth = 'xl',
  mainIcon,
}: {
  mainIcon?: ReactNode;
  maxWidth?: ContainerProps['maxWidth'];
}) => {
  return (
    <Container maxWidth={maxWidth}>
      <Stack py={2} direction="row" alignItems="center">
        {mainIcon || (
          <IconButton component={Link} to="/" size="small">
            <LogoIcon sx={logoStyles} />
          </IconButton>
        )}
      </Stack>
    </Container>
  );
};

export { Header };
