import React, { useCallback } from 'react';
import { ColorFunction } from '../../MapView/types';
import { defaultFindColorFunctions } from '../../MapView/utils/defaultColorFunctions';

interface FindFilter {
  id: string;
  stored?: boolean;
  eras?: string[];
  findTypes?: string[];
  materials?: string[];
  sites?: string[];
  groups?: string[];
  tags?: string[];
}

interface SiteFilter {
  eras?: string[];
  tags?: string[];
}

type SettingsStoreState = {
  mapDrawerOpen: boolean;
  mainDrawerIndex: number;
  findFilters: FindFilter[];
  siteFilters: SiteFilter[];
  activeFindFilter: FindFilter | null;
  selectedFind: string | null;
  findLayersEnabled: boolean;
  heatmapLayerEnabled: boolean;
  isadLayerEnabled: boolean;
  objectLayerEnabled: boolean;
  findColorFunction: ColorFunction;
};

enum SettingsActionType {
  SET_MAIN_DRAWER_INDEX = 'SET_MAIN_DRAWER_INDEX',
  CREATE_FIND_FILTER = 'CREATE_FIND_FILTER',
  UPDATE_FIND_FILTER = 'UPDATE_FIND_FILTER',
  DISABLE_FIND_FILTER = 'DISABLE_FIND_FILTER',
  TOGGLE_FIND_LAYERS = 'TOGGLE_FIND_LAYERS',
  TOGGLE_HEATMAP_LAYER = 'TOGGLE_HEATMAP_LAYER',
  TOGGLE_ISAD_LAYER = 'TOGGLE_ISAD_LAYER',
  TOGGLE_MAP_DRAWER = 'TOGGLE_MAP_DRAWER',
  TOGGLE_OBJECT_LAYER = 'TOGGLE_OBJECT_LAYER',
  SET_FIND_COLOR_FUNCTION = 'SET_FIND_COLOR_FUNCTION',
  SET_SELECTED_FIND = 'SET_SELECTED_FIND',
}

type SettingsStoreAction = {
  type: SettingsActionType;
  payload?: any;
};

const settingsReducer = (
  state: SettingsStoreState,
  action: SettingsStoreAction
) => {
  switch (action.type) {
    case 'CREATE_FIND_FILTER':
      const newFilter = {
        id: new Date().toISOString(),
        stored: false,
        ...action.payload,
      };
      return {
        ...state,
        findFilters: [...state.findFilters, newFilter],
        activeFindFilter: newFilter,
      };
    case 'DISABLE_FIND_FILTER':
      return {
        ...state,
        activeFindFilter: null,
      };
    case 'UPDATE_FIND_FILTER':
      // TODO: use this when we have saving implemented return { ...state, findFilters: [...state.findFilters, action.payload] };
      const updatedFilter = action.payload;
      return {
        ...state,
        findFilters: updatedFilter.stored
          ? state.findFilters
          : [
              ...state.findFilters.filter((f) => f.id !== updatedFilter.id),
              updatedFilter,
            ],
        activeFindFilter: updatedFilter,
      };
    case 'TOGGLE_FIND_LAYERS':
      return {
        ...state,
        findLayersEnabled: !state.findLayersEnabled,
      };
    case 'TOGGLE_HEATMAP_LAYER':
      return {
        ...state,
        heatmapLayerEnabled: !state.heatmapLayerEnabled,
      };
    case 'TOGGLE_ISAD_LAYER':
      return {
        ...state,
        isadLayerEnabled: !state.isadLayerEnabled,
      };
    case 'TOGGLE_OBJECT_LAYER':
      return {
        ...state,
        objectLayerEnabled: !state.objectLayerEnabled,
      };
    case 'TOGGLE_MAP_DRAWER':
      return {
        ...state,
        mapDrawerOpen: !state.mapDrawerOpen,
      };
    case 'SET_MAIN_DRAWER_INDEX':
      return {
        ...state,
        mainDrawerIndex: action.payload,
      };
    case 'SET_SELECTED_FIND':
      return {
        ...state,
        selectedFind: action.payload,
      };
    case 'SET_FIND_COLOR_FUNCTION':
      return { ...state, findColorFunction: action.payload };
    default:
      return state;
  }
};

function createCtx(defaultValue: SettingsStoreState) {
  const ctx = React.createContext({
    store: defaultValue,
    setMainDrawerIndex: null,
    setSelectedFind: null,
    addFindsFilter: null,
    disableFindsFilter: null,
    updateFindsFilter: null,
    toggleFindLayer: null,
    toggleHeatmapLayer: null,
    toggleIsadLayer: null,
    toggleObjectLayer: null,
    toggleMapDrawer: null,
    setFindColorFunction: null,
  });

  function SettingsProvider(props: React.PropsWithChildren<{}>) {
    const [store, dispatch] = React.useReducer(settingsReducer, defaultValue);

    const setMainDrawerIndex = useCallback(
      (index) => {
        dispatch({
          type: SettingsActionType.SET_MAIN_DRAWER_INDEX,
          payload: index,
        });
      },
      [dispatch]
    );

    const setSelectedFind = useCallback(
      (id) => {
        dispatch({
          type: SettingsActionType.SET_SELECTED_FIND,
          payload: id,
        });
      },
      [dispatch]
    );

    const addFindsFilter = useCallback(
      (options) => {
        dispatch({
          type: SettingsActionType.CREATE_FIND_FILTER,
          payload: options,
        });
      },
      [dispatch]
    );

    const disableFindsFilter = useCallback(() => {
      dispatch({ type: SettingsActionType.DISABLE_FIND_FILTER });
    }, [dispatch]);

    const updateFindsFilter = useCallback(
      (newFilter) => {
        dispatch({
          type: SettingsActionType.UPDATE_FIND_FILTER,
          payload: newFilter,
        });
      },
      [dispatch]
    );

    const toggleFindLayer = useCallback(
      () => dispatch({ type: SettingsActionType.TOGGLE_FIND_LAYERS }),
      [dispatch]
    );

    const toggleHeatmapLayer = useCallback(
      () => dispatch({ type: SettingsActionType.TOGGLE_HEATMAP_LAYER }),
      [dispatch]
    );

    const toggleIsadLayer = useCallback(
      () => dispatch({ type: SettingsActionType.TOGGLE_ISAD_LAYER }),
      [dispatch]
    );

    const toggleObjectLayer = useCallback(
      () => dispatch({ type: SettingsActionType.TOGGLE_OBJECT_LAYER }),
      [dispatch]
    );

    const toggleMapDrawer = useCallback(
      () => dispatch({ type: SettingsActionType.TOGGLE_MAP_DRAWER }),
      [dispatch]
    );

    const setFindColorFunction = useCallback(
      (payload: ColorFunction) => {
        dispatch({
          type: SettingsActionType.SET_FIND_COLOR_FUNCTION,
          payload,
        });
      },
      [dispatch]
    );

    const value = React.useMemo(
      () => ({
        store,
        addFindsFilter,
        disableFindsFilter,
        setMainDrawerIndex,
        setSelectedFind,
        updateFindsFilter,
        toggleFindLayer,
        toggleHeatmapLayer,
        toggleIsadLayer,
        toggleObjectLayer,
        toggleMapDrawer,
        setFindColorFunction,
      }),
      [
        store,
        addFindsFilter,
        disableFindsFilter,
        setMainDrawerIndex,
        setSelectedFind,
        updateFindsFilter,
        toggleFindLayer,
        toggleIsadLayer,
        toggleHeatmapLayer,
        toggleObjectLayer,
        toggleMapDrawer,
        setFindColorFunction,
      ]
    );

    return <ctx.Provider value={value} {...props} />;
  }

  return [ctx, SettingsProvider] as [typeof ctx, typeof SettingsProvider];
}

const [SettingsContext, SettingsProvider] = createCtx({
  mainDrawerIndex: 0,
  mapDrawerOpen: true,
  activeFindFilter: null,
  findFilters: [],
  siteFilters: [],
  selectedFind: null,
  findLayersEnabled: true,
  heatmapLayerEnabled: false,
  isadLayerEnabled: false,
  objectLayerEnabled: false,
  findColorFunction: defaultFindColorFunctions.defaultMaterial,
});

export { SettingsContext, SettingsProvider };
