import { useQuery } from '@apollo/client';
import React, { ReactNode, useMemo } from 'react';
import {
  Breadcrumbs as BreadcrumbsComponent,
  Link,
  Typography,
} from '@mui/material';
import {
  Link as BaseLink,
  Location,
  useLocation,
  useParams,
} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

import { GET_MY_FIND_QUERY, GET_MY_SITE_QUERY } from '../../gql';

const breadcrumbsStyles = {
  '& .MuiBreadcrumbs-ol': {
    alignItems: 'normal',
    flexWrap: 'nowrap',
  },
  '& .MuiBreadcrumbs-li': {
    flexShrink: 1,
    maxWidth: { xs: '80px', sm: 'unset' },
  },
  '& .MuiBreadcrumbs-li > a': {
    display: 'block',
  },
};

const getSectionCrumbs = (
  location: Location,
  siteName?: string,
  find?: { id: string; siteId?: string }
): ReactNode[] => {
  const parts = location.pathname.slice(1).split('/');
  let crumbs = [];

  if (parts[0] === 's') {
    // we are in the site namespace
    crumbs = [
      ...crumbs,
      <Link
        to="/s"
        underline="hover"
        key="list-sites"
        color="inherit"
        component={BaseLink}
      >
        Sites
      </Link>,
    ];

    if (!parts[1]) {
      return crumbs;
    }

    if (!siteName) {
      return [...crumbs, <Typography noWrap>Create site</Typography>];
    }

    return [...crumbs, <Typography noWrap>{siteName}</Typography>];

    //${parts[1].charAt(0).toUpperCase()}${parts[1].slice(1)}` for subsection
  }

  if (parts[0] === 'f') {
    // we are in the find namespace
    if (!!siteName) {
      crumbs = [
        ...crumbs,
        <Link
          to="/s"
          underline="hover"
          key="list-sites"
          color="inherit"
          component={BaseLink}
        >
          Sites
        </Link>,
        <Link
          to={`/s/${find.siteId}`}
          underline="hover"
          key={`site-${find.siteId}`}
          color="inherit"
          component={BaseLink}
          noWrap
        >
          {siteName}
        </Link>,
        <Link
          to={`/s/${find.siteId}/finds`}
          underline="hover"
          key="2"
          color="inherit"
          component={BaseLink}
        >
          Finds
        </Link>,
        <Typography noWrap>{find.id}</Typography>,
      ];
    }
  }

  // we are elsewhere
  return crumbs;
};

const Breadcrumbs = () => {
  const location = useLocation();
  const { findId, siteId } = useParams();

  const { called: findCalled, data: findData, loading: findLoading } = useQuery(
    GET_MY_FIND_QUERY,
    {
      variables: { findId },
      fetchPolicy: 'cache-first',
      skip: !findId,
    }
  );

  const find = findData?.getMyFind;

  const { called: siteCalled, data: siteData, loading: siteLoading } = useQuery(
    GET_MY_SITE_QUERY,
    {
      variables: { siteId: siteId || find?.siteId },
      fetchPolicy: 'cache-first',
      skip: !(siteId || find?.siteId),
    }
  );

  const siteName = siteData?.getMySite?.name;

  const breadcrumbs = useMemo(
    () => [
      <Link
        underline="hover"
        to="/"
        key="1"
        color="inherit"
        component={BaseLink}
      >
        <HomeIcon color="action" />
      </Link>,
      ...getSectionCrumbs(location, siteName, find),
    ],
    [siteName, find, location]
  );

  return (
    <BreadcrumbsComponent
      sx={breadcrumbsStyles}
      separator="›"
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </BreadcrumbsComponent>
  );
};

export { Breadcrumbs };
