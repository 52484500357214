import { useMutation } from '@apollo/client';
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Input,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { grey, blueGrey } from '@mui/material/colors';
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { LogoIcon } from '../../components/Icons/LogoIcon';

import { AuthQueryName, SIGN_IN_MUTATION } from '../../gql';

type FormValues = {
  email: string;
  password: string;
};

const containerStyles = { height: '100%', display: 'flex' };
const rootStyles = { width: '100%', height: '100%', background: blueGrey[900] };
const stackStyles = {
  boxShadow: `0 8px 24px ${grey[800]}`,
  width: '100%',
  background: 'white',
  borderRadius: 2,
};
const submitButtonStyles = {
  borderRadius: 2,
  fontSize: '1rem',
};
const logoStyles = { fontSize: 64 };

const SignIn = React.memo(() => {
  const { register, handleSubmit } = useForm<FormValues>();
  const [errors, setErrors] = useState([]);

  const [signIn, { loading }] = useMutation(SIGN_IN_MUTATION, {
    refetchQueries: [AuthQueryName],
    onCompleted: ({ signIn: { __typename, ...data } }) => {
      // Whatever we need to do on completed
    },
    // onError:
  });

  const onSubmit = async (values: { email: string; password: string }) => {
    const { data } = await signIn({
      variables: {
        email: values.email,
        password: values.password,
      },
    });

    if (data && !data.signIn.errors) {
      // Whatever we need to do on success
    } else {
      setErrors(data!.signIn!.errors);
    }
  };

  return (
    <div style={rootStyles}>
      <Helmet title="Sign-in"></Helmet>
      <Container maxWidth="xs" sx={containerStyles}>
        <Stack
          spacing={2}
          alignItems="flex-start"
          justifyContent="center"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={stackStyles}
          px={4}
          pt={8}
          pb={12}
          mt={18}
          mb="auto"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            width="100%"
          >
            <LogoIcon sx={logoStyles} />
            <Typography textAlign="center" fontWeight="bold" variant="h2">
              Acolyte
            </Typography>
          </Stack>
          <Stack width="100%">
            <InputLabel htmlFor="email">E-mail</InputLabel>
            <Input
              fullWidth
              id="email"
              type="text"
              placeholder="e-mail"
              {...register('email')}
            />
          </Stack>
          <Stack width="100%">
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              fullWidth
              id="password"
              type="password"
              {...register('password')}
            />
          </Stack>
          <Divider color="transparent" />
          <Button
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
            sx={submitButtonStyles}
            startIcon={
              loading && <CircularProgress color="inherit" size="24px" />
            }
          >
            Sign in
          </Button>
          <Divider />
          <Stack width="100%">
            {errors.length > 0 && (
              <Stack>
                {errors.map((item: { error: string }) => (
                  <Typography color="red">{item.error}</Typography>
                ))}
              </Stack>
            )}
          </Stack>
          <Stack width="100%" alignItems="center">
            <Stack direction="row" justifyContent="center" width="100%">
              <Typography variant="caption">
                Don't have an account?{' '}
                <Link to="/signup" style={{ color: '#FF0070' }}>
                  Create one
                </Link>
              </Typography>
            </Stack>
            <Typography variant="caption">
              <Link to="/" style={{ color: '#D76B90' }}>
                Home
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
});

export { SignIn };
