import { gql } from '@apollo/client';

export const GENERATE_LABELS_MUTATION = gql`
  mutation GenerateLabelsMutation($id: String!) {
    generateLabels(id: $id) {
      ... on GenerateLabelsSuccess {
        url
      }
      ... on ValidationError {
        errors {
          error
          path
        }
      }
    }
  }
`;
