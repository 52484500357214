import React, { useEffect } from 'react';
import Map, { Marker } from 'react-map-gl';
import { MAPBOX_ACCESS_TOKEN } from '../../consts';

const PreviewMap = ({
  longitude,
  latitude,
}: {
  longitude: number;
  latitude: number;
}) => {
  const [viewState, setViewState] = React.useState({
    longitude,
    latitude,
    zoom: 15,
  });

  useEffect(() => {
    setViewState((prev) => ({ longitude, latitude, zoom: prev.zoom }));
  }, [longitude, latitude]);

  return (
    <Map
      {...viewState}
      onMove={(evt) => setViewState(evt.viewState)}
      mapStyle="mapbox://styles/mapbox/satellite-v9"
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
    >
      <Marker longitude={longitude} latitude={latitude} anchor="bottom" />
    </Map>
  );
};

export { PreviewMap };
