import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_SITE_FINDS_QUERY } from '../../../gql';
import FindGrid from './FindGrid';
import { FindGallery } from './FindGallery';

interface SiteFindsProps {
  findListMode: 'grid' | 'gallery';
}

const SiteFinds = React.memo(({ findListMode }: SiteFindsProps) => {
  const { siteId } = useParams();
  const galleryMode = findListMode === 'gallery';
  const { data, loading } = useQuery(GET_SITE_FINDS_QUERY, {
    variables: { siteId, pagination: { limit: 1000 } },
    fetchPolicy: 'cache-first',
    skip: !siteId,
  });

  const finds = data?.getSiteFinds || [];

  if (galleryMode) {
    return <FindGallery finds={finds} loading={loading} />;
  }

  return <FindGrid rows={finds} loading={loading} />;
});

export { SiteFinds };
