import React from 'react';

import { SiteSkeleton } from './SiteSkeleton';
import { SiteNotFound } from './SiteNotFound';
import { SiteForm } from './SiteForm';
import { SiteData } from '../types';

const SiteSettings = React.memo(
  ({ called, loading, site, siteId }: SiteData) => {
    return (
      <>
        {loading && <SiteSkeleton />}
        {called && !loading && !site && <SiteNotFound />}
        {(!siteId || (called && site)) && <SiteForm id={siteId} site={site} />}
      </>
    );
  }
);

export { SiteSettings };
