import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { useQuery } from '@apollo/client';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'yet-another-react-lightbox/dist/styles.css';

import { Home } from './pages/Home';
import { AUTH_QUERY } from './gql';
import { SettingsProvider } from './components/context/SettingsContext';
import { LayerProvider } from './components/LayerContext';
import { resetStyles, theme } from './theme';
import { SignIn } from './pages/SignIn';
import { SignUp } from './pages/SignUp';
import { FullScreenLoader, GoToProvider } from './components';
import { Account } from './pages/Account';
import { Sites } from './pages/Sites';
import { Site } from './pages/Site';
import { Find } from './pages/Find';
import { Contacts } from './pages/Contacts';
import { WorkspaceProvider } from './components/context/WorkspaceContext';

const globalStyles = <GlobalStyles styles={resetStyles} />;

const AppLayout = () => {
  const { loading, data } = useQuery(AUTH_QUERY);
  const user = data ? data.viewer.user : null;

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <Routes>
      <Route path="/" element={<Home isSignedIn={user != null} />} />{' '}
      <Route
        path="/m/:shareSlug"
        element={<Home isSignedIn={user != null} />}
      />
      {!user ? (
        <>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
        </>
      ) : (
        <>
          <Route path="/account" element={<Account />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/s/:siteId/f/:findId/edit/*" element={<Find />} />
          <Route path="/s/:siteId/f/create" element={<Find />} />
          <Route path="/f/create" element={<Find />} />
          <Route path="/f/:findId/edit/*" element={<Find />} />
          <Route path="/s" element={<Sites />} />
          <Route path="/s/create" element={<Site />} />
          <Route path="/s/:siteId/*" element={<Site />} />
          <Route path="/signin" element={<Navigate to="/" />} />
          <Route path="/signup" element={<Navigate to="/" />} />
          <Route path="*" element={<div>404</div>} />
        </>
      )}
    </Routes>
  );
};

export const App = () => (
  <ThemeProvider theme={theme}>
    {globalStyles}
    <LayerProvider>
      <GoToProvider>
        <SettingsProvider>
          <WorkspaceProvider>
            <AppLayout />
          </WorkspaceProvider>
        </SettingsProvider>
      </GoToProvider>
    </LayerProvider>
  </ThemeProvider>
);
