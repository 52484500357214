import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import {
  GET_MY_CONTACTS_QUERY,
  GET_SITE_COLLABORATORS_QUERY,
} from '../../../gql';
import AccessGrid from './AccessGrid';

const SiteAccess = React.memo(() => {
  const { siteId } = useParams();

  const { data: contactsData, loading: contactsLoading } = useQuery(
    GET_MY_CONTACTS_QUERY,
    {
      fetchPolicy: 'cache-first',
    }
  );

  const { data: collaboratorsData, loading: collaboratorsLoading } = useQuery(
    GET_SITE_COLLABORATORS_QUERY,
    {
      variables: { siteId, pagination: { limit: 1000 } },
      fetchPolicy: 'cache-first',
    }
  );

  const contacts = contactsData?.getMyContacts?.contacts || [];
  const collaborators = collaboratorsData?.getSiteCollaborators || [];

  return <AccessGrid siteId={siteId} data={collaborators} contacts={contacts} />;
});

export { SiteAccess };
