import { gql } from '@apollo/client';

export const GetMySitesQueryName = 'GetMySites';

export const GET_MY_SITES_QUERY = gql`
  query ${GetMySitesQueryName} {
    getMySites {
      id
      createdAt
      createdBy
      description
      eraId
      localName
      name
      longitude
      latitude
      shared
      siteType
      visibility
      updatedAt
    }
  }
`;
