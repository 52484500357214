import { styled } from '@mui/material/styles';

interface StyledSuperscriptProps {
  $error?: boolean;
}

interface RequiredLabelProps {
  error?: boolean;
}

const StyledSuperscript = styled('sup')<StyledSuperscriptProps>`
  padding: 1px 4px;
  font-weight: 500;
  font-size: 0.6rem;
  border-radius: 1rem;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) =>
    props.$error
      ? '#ff2020'
      : `${props.theme.palette.mode === 'dark' ? '#e0e0e0' : '#424242'}`};
`;

const RequiredLabel = ({ error }: RequiredLabelProps) => (
  <StyledSuperscript $error={error}>required</StyledSuperscript>
);

export { RequiredLabel };
