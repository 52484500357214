import { gql } from '@apollo/client';

export const CREATE_FIND_MUTATION = gql`
  mutation CreateFindMutation($find: FindInput!) {
    createFind(find: $find) {
      ... on CreateFindSuccess {
        find {
          id
          createdAt
          description
          updatedAt
          foundAt
          material
          era
          type
          note
          latitude
          longitude
          shared
          visibility
        }
      }
      ... on ValidationError {
        errors {
          error
          path
        }
      }
    }
  }
`;
