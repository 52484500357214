export const materials = {
  brass: 'rgb(93, 153, 77)',
  bronze: 'rgb(66, 182, 34)',
  iron: 'rgb(172, 49, 49)',
  silver: 'rgb(174, 216, 255)',
  lead: 'rgb(108, 108, 108)',
  stone: 'rgb(108, 70, 70)',
  glass: 'rgb(28, 110, 255)',
  gold: 'rgb(255, 197, 14)',
  copper: 'rgb(244, 0, 53)',
};

export const getMaterialColor = (
  material: string,
  draft?: boolean
): [number, number, number] => {
  if (draft) {
    return [252, 3, 161];
  }

  if (material === 'bronze') {
    return [66, 182, 34];
  }

  if (material === 'brass') {
    return [93, 153, 77];
  }

  if (material === 'iron') {
    return [172, 49, 49];
  }

  if (material === 'silver') {
    return [174, 216, 255];
  }

  if (material === 'lead') {
    return [108, 108, 108];
  }

  if (material?.includes('stone')) {
    return [108, 70, 70];
  }

  if (material === 'glass') {
    return [28, 110, 255];
  }

  if (material === 'gold') {
    return [255, 197, 14];
  }

  return [244, 0, 53];
};
