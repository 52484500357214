const handleUploadImage = async (
  file: File,
  uploadUrl: string,
  id: string
): Promise<string | undefined> => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    await fetch(uploadUrl, {
      method: 'POST',
      body: formData,
    });

    return id;
  } catch (error) {
    console.error(error);
  }
};

export { handleUploadImage };
