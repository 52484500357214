export * from './siteTypes';

export const BASE_MAPBOX_STYLE_URL =
  'mapbox://styles/jhrib/ckulpks8fg68l19ns3zyao7xn/draft';

export const WELCOME_SCREEN_MAPBOX_STYLE_URL =
  'mapbox://styles/mapbox/dark-v11';

export const DEFAULT_INITIAL_COORDS = {
  longitude: 14.4464964,
  latitude: 50.0701525,
  zoom: 10,
};

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiamhyaWIiLCJhIjoiY2t1bGR0aW1kMDhsczJ1dGhwMWRzdTQ2MCJ9.hlTq6_yVzr8fTTSWp1wO7w';

export const DRAWER_BLEEDING = 16;