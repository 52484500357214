import React from 'react';
import { useQuery } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { GET_MY_FINDS_QUERY } from '../../gql';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginBottom: 1,
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const FindsChronology = React.memo(() => {
  const { data, loading } = useQuery(GET_MY_FINDS_QUERY, {
    fetchPolicy: 'cache-only',
  });

  if (loading || data == null) {
    return null;
  }

  const eras = data.getMyFinds.reduce((acc, { era }) => {
    if (typeof acc[era] === 'undefined') {
      return { ...acc, [era]: 1 };
    }
    return { ...acc, [era]: acc[era] + 1 };
  }, {});

  return (
    <Stack my={2}>
      <Typography mr={1} variant="body1" gutterBottom>
        All Finds by era
      </Typography>
      {Object.keys(eras)
        .sort((a, b) => eras[b] - eras[a])
        .map((era) => (
          <React.Fragment key={era}>
            <Typography
              fontWeight="bold"
              mt={1}
              variant="caption"
              textTransform="capitalize"
            >
              {era}{' '}
              <Typography variant="caption" display="inline">
                ({eras[era]})
              </Typography>
            </Typography>
            <BorderLinearProgress
              key={era}
              variant="determinate"
              value={Math.floor((eras[era] / data.getMyFinds.length) * 100)}
            />
          </React.Fragment>
        ))}
    </Stack>
  );
});

export { FindsChronology };
