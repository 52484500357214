import React from 'react';
import { Link as BaseLink, Stack } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { Link, LinkProps, matchPath, useLocation } from 'react-router-dom';

interface SideNavItemProps {}

const StyledLink = styled(Link)<LinkProps & { active?: boolean }>`
  box-sizing: border-box;
  padding: 6px 0px 6px 12px;
  border-left: 1px solid transparent;
  display: block;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #1c2025;
  font-size: 0.9em;

  &:hover {
    color: #6b7a90;
  }

  &:active {
    border-left-color: #99ccf3;
    color: #0072e5;
  }

  ${(props) =>
    props.active &&
    css`
      border-left-color: #99ccf3;
      color: #0072e5;

      &:hover {
        color: #0072e5;
        border-left-color: #0072e5;
      }
    `}
`;

const SideNavItem = styled('li')<SideNavItemProps>`
  list-style: none;
`;

const listStyles = { margin: 0, padding: 0, flexShrink: 0 };

const FindMenu = React.memo(() => {
  const location = useLocation();

  return (
    <Stack width={150} component="ul" sx={listStyles}>
      <SideNavItem>
        <BaseLink
          active={Boolean(matchPath('/f/:findId/', location.pathname))}
          color="1c2025"
          underline="none"
          component={StyledLink}
          to=""
        >
          Settings
        </BaseLink>
        <BaseLink
          active={Boolean(matchPath('/f/:findId/images', location.pathname))}
          color="1c2025"
          underline="none"
          component={StyledLink}
          to="images"
        >
          Images
        </BaseLink>
      </SideNavItem>
    </Stack>
  );
});

export { FindMenu };
