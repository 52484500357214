import { gql } from '@apollo/client';

export const GetMyContactsQueryName = 'GetMyContacts';

export const GET_MY_CONTACTS_QUERY = gql`
  query ${GetMyContactsQueryName} {
    getMyContacts {
      contacts {
        id
        email
        displayName
        createdAt
        responderUser
      }
      contactRequests {
        id
        email
        seen
        displayName
        createdAt
      }
      sentRequests {
        createdAt
        email
      }
    }
  }
`;
