const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

export { isServer };
export * from './formHelpers';
export * from './ioHelpers';
export * from './mapHelpers';
export * from './parseInitialCoordinates';
export * from './useYupValidationResolver';
export * from './wgs84ToJtsk';
export * from './wgs84ToJtskPrecise';
export * from './wgs84toWebMercator';
