import { gql } from '@apollo/client';

export const REMOVE_SITE_COLLABORATOR_MUTATION = gql`
  mutation RemoveSiteCollaboratorMutation($siteId: String!, $userId: String!) {
    removeSiteCollaborator(siteId: $siteId, userId: $userId) {
      ... on RemoveSiteCollaboratorSuccess {
        result
      }
      ... on ValidationError {
        errors {
          error
          path
        }
      }
    }
  }
`;
