import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from '@mui/material/LinearProgress';

import { materials } from '../../helpers';

const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'materialColor',
})<LinearProgressProps & { materialColor: string }>(
  ({ theme, materialColor }) => ({
    marginBottom: 1,
    marginRight: 3,
    width: 10,
    height: 10,
    borderRadius: 5,
    color: materialColor,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: materialColor,
    },
  })
);

const MaterialLegend = React.memo(() => {
  return (
    <Stack my={2}>
      <Typography mr={1} variant="body1" gutterBottom>
        Material legend
      </Typography>
      {Object.keys(materials).map((material) => (
        <Stack direction="row" alignItems="center" key={material}>
          <BorderLinearProgress
            materialColor={materials[material]}
            variant="determinate"
            value={100}
          />
          <Typography
            fontWeight="bold"
            variant="caption"
            textTransform="capitalize"
          >
            {material}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
});

export { MaterialLegend };
