import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import { useQuery } from '@apollo/client';

import { GET_MY_SITES_QUERY } from '../../gql';
import { useGoTo } from '../GoToContext/useGoTo';
import { SiteListControls } from './SiteListControls';

const linkStyles = { cursor: 'pointer', display: 'inline' };
const iconButtonStyles = { padding: 0 };

const SiteList = () => {
  const navigate = useNavigate();
  const { goTo } = useGoTo();

  const getHandleClickGoto = (latitude: number, longitude: number) => () => {
    goTo(longitude, latitude);
  };

  const getHandleClickDetail = (id: string) => () => {
    navigate(`/s/${id}`);
  };

  const { data, loading } = useQuery(GET_MY_SITES_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const rows = data?.getMySites || [];

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      subheader={<SiteListControls />}
    >
      {rows.map((row, index) => (
        <React.Fragment key={row.name}>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemText
              primary={
                <Stack
                  alignItems="center"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Link
                    component={Typography}
                    fontWeight={500}
                    onClick={getHandleClickDetail(row.id)}
                    underline="none"
                    sx={linkStyles}
                  >
                    {row.name}
                  </Link>
                  <Stack direction="row">
                    {row.latitude && row.longitude && (
                      <IconButton
                        onClick={getHandleClickGoto(
                          row.latitude,
                          row.longitude
                        )}
                        sx={iconButtonStyles}
                        aria-label="delete"
                        size="small"
                      >
                        <AssistantDirectionIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </Stack>
                </Stack>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {row.shared && 'Shared, '}
                  </Typography>
                  Last updated{' '}
                  {DateTime.fromISO(row.updatedAt).toRelative({ locale: 'en' })}
                  <br />
                  {!!row.siteType ? `type: ${row.siteType}` : ''}
                </React.Fragment>
              }
            />
          </ListItem>
          {rows.length !== index + 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

export { SiteList };
