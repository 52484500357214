import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Autocomplete,
  Button,
  Unstable_Grid2 as Grid,
  Input,
  InputLabel,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import {
  CREATE_SITE_MUTATION,
  GET_MY_SITES_QUERY,
  UPDATE_SITE_MUTATION,
} from '../../../gql';
import { siteTypes } from '../../../consts';

interface SiteFormProps {
  id: string;
  site: {
    name: string;
    latitude?: string | number;
    longitude?: string | number;
    description?: string;
    localName?: string;
    siteType?: string;
    visibility: 'public' | 'private';
    shared?: boolean;
  };
}

const containerStyles = { maxWidth: 640, flexGrow: 1 };
const inputStyles = { width: '100%' };
const gpsInputStyles = { width: '100%' };
const labelStyles = { fontWeight: 600 };
const submitButtonStyles = {
  borderRadius: 2,
  fontSize: '1rem',
};
const successAlertStyles = { width: '100px' };

const SiteForm = React.memo(({ id, site }: SiteFormProps) => {
  const navigate = useNavigate();
  const [
    updateSite,
    { called: calledUpdate, loading: loadingUpdate },
  ] = useMutation(UPDATE_SITE_MUTATION, {
    refetchQueries: [{ query: GET_MY_SITES_QUERY }],
  });
  const [
    createSite,
    { called: calledCreate, loading: loadingCreate },
  ] = useMutation(CREATE_SITE_MUTATION, {
    onCompleted: ({ createSite: { __typename, site } }) => {
      if (site) {
        navigate(`/s/${site.id}`);
      }
    },
    refetchQueries: [{ query: GET_MY_SITES_QUERY }],
  });
  const called = calledUpdate || calledCreate;
  const loading = loadingUpdate || loadingCreate;

  const defaultValues = site
    ? {
        name: site.name,
        description: site.description,
        localName: site.localName,
        siteType: site.siteType,
        latitude: site.latitude,
        longitude: site.longitude,
        public: site.visibility === 'public',
      }
    : {};
  const isShared = false; // Boolean(site?.shared);

  const {
    reset,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });
  const onSubmit = async (data) => {
    if (id) {
      await updateSite({
        variables: {
          id,
          site: {
            name: data.name,
            description: data.description,
            localName: data.localName,
            siteType: data.siteType,
            latitude: data.latitude && parseFloat(data.latitude.toString()),
            longitude: data.longitude && parseFloat(data.longitude.toString()),
            visibility: data.public ? 'public' : 'private',
          },
        },
      });
    } else {
      await createSite({
        variables: {
          site: {
            name: data.name,
            description: data.description,
            localName: data.localName,
            siteType: data.siteType,
            latitude: data.latitude && parseFloat(data.latitude.toString()),
            longitude: data.longitude && parseFloat(data.longitude.toString()),
            visibility: data.public ? 'public' : 'private',
          },
        },
      });
    }

    // add yup validation and error handling and displaying

    reset(data);
  };

  return (
    <Stack
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      gap={2}
      sx={containerStyles}
    >
      <Grid container spacing={2}>
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={2}
        >
          <InputLabel sx={labelStyles} htmlFor="site-name">
            Site name
          </InputLabel>
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={10}>
          <Controller
            disabled={isShared}
            name="name"
            control={control}
            render={({ field }) => (
              <Input id="site-name" type="text" sx={inputStyles} {...field} />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="baseline">
        <Grid display="flex" justifyContent="flex-end" xs={3} md={2}>
          <InputLabel sx={labelStyles} htmlFor="site-description">
            Description
          </InputLabel>
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={10}>
          <Controller
            name="description"
            disabled={isShared}
            control={control}
            render={({ field }) => (
              <Input
                multiline
                minRows={2}
                maxRows={2}
                sx={inputStyles}
                id="site-description"
                defaultValue={defaultValues.description}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={2}
        >
          <InputLabel sx={labelStyles} htmlFor="site-local-name">
            Local name
          </InputLabel>
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={10}>
          <Controller
            name="localName"
            disabled={isShared}
            control={control}
            render={({ field }) => (
              <Input
                id="site-local-name"
                type="text"
                sx={inputStyles}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={2}
        >
          <InputLabel sx={labelStyles} htmlFor="site-type">
            Site type
          </InputLabel>
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={10}>
          <Controller
            name="siteType"
            disabled={isShared}
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                disablePortal
                id="find-material"
                options={siteTypes}
                defaultValue={defaultValues.siteType}
                onChange={(event, newValue) => onChange(newValue)}
                renderInput={(params) => (
                  <TextField
                    sx={inputStyles}
                    inputRef={ref}
                    {...params}
                    {...field}
                  />
                )}
                sx={inputStyles}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={2}
        >
          <InputLabel sx={labelStyles} htmlFor="site-latitude">
            GPS
          </InputLabel>
        </Grid>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          xs={9}
          md={10}
        >
          <Grid justifyContent="flex-start" alignItems="center" xs={6}>
            <Controller
              name="latitude"
              disabled={isShared}
              control={control}
              render={({ field }) => (
                <Input
                  id="site-latitude"
                  type="text"
                  sx={gpsInputStyles}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid justifyContent="flex-start" alignItems="center" xs={6}>
            <Controller
              name="longitude"
              control={control}
              disabled={isShared}
              render={({ field }) => (
                <Input
                  id="site-longitude"
                  type="text"
                  sx={gpsInputStyles}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
          md={2}
        >
          <InputLabel sx={labelStyles} htmlFor="site-visibility">
            Public
          </InputLabel>
        </Grid>
        <Grid justifyContent="flex-start" alignItems="center" xs={9} md={10}>
          <Controller
            name="public"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch
                id="site-visibility"
                disabled={isShared}
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          mdOffset={2}
          justifyContent="flex-start"
          alignItems="center"
          xs={8}
        >
          <Stack direction="row" gap={1}>
            <Button
              disabled={loading || !isDirty || isShared}
              type="submit"
              variant="contained"
              sx={submitButtonStyles}
            >
              Submit
            </Button>
            {called && !isDirty && (
              <Alert sx={successAlertStyles} severity="success">
                Saved!
              </Alert>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
});

export { SiteForm };
