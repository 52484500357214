import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GET_ALL_FILTER_TYPES_QUERY, GET_MY_SITES_QUERY } from '../../gql';
import { FindSkeleton } from './FindSkeleton';
import { FindNotFound } from './FindNotFound';
import { FindForm } from './FindForm';
import { FindData } from '../../pages/Find/types';

const FindSettings = React.memo(
  ({ called, loading, find, findId, forSite }: FindData) => {
    const {
      data: availableFiltersData,
      loading: availableFiltersLoading,
    } = useQuery(GET_ALL_FILTER_TYPES_QUERY, {
      fetchPolicy: 'cache-first',
    });

    const { data: sitesData, loading: sitesLoading } = useQuery(
      GET_MY_SITES_QUERY,
      {
        fetchPolicy: 'cache-first',
      }
    );

    const sites = sitesData?.getMySites || [];

    const materials = useMemo(
      () =>
        (availableFiltersData?.getAllFilterTypes?.materials || []).toSorted(
          (a, b) => a - b
        ),
      [availableFiltersData?.getAllFilterTypes?.materials]
    );

    const types = useMemo<Array<{ id: string; category: string }>>(
      () =>
        (availableFiltersData?.getAllFilterTypes?.findTypes || []).toSorted(
          (a, b) => a - b
        ),
      [availableFiltersData?.getAllFilterTypes?.findTypes]
    );

    const eras = useMemo<Array<{ id: string; parentId: string }>>(
      () =>
        (availableFiltersData?.getAllFilterTypes?.eras || [])
          .filter((e) => e.parentId)
          // Adding Indeterminate dating because originally we have it only as a parent item that gets filtered out
          .concat([
            {
              id: 'Indeterminate dating',
              parentId: 'Indeterminate dating',
              ordinal: 26,
            },
          ])
          .map((e) => ({ ...e, id: e.id.toLowerCase() }))
          .toSorted((a, b) => a - b),
      [availableFiltersData?.getAllFilterTypes?.eras]
    );

    const tags = useMemo<Array<string>>(
      () =>
        (availableFiltersData?.getAllFilterTypes?.tags || []).map(
          (t) => t.name
        ),
      [availableFiltersData?.getAllFilterTypes?.tags]
    );

    if (loading || sitesLoading || availableFiltersLoading) {
      return <FindSkeleton />;
    }

    return (
      <>
        {called && !loading && !find && <FindNotFound />}
        {(!findId || (called && find)) && (
          <FindForm
            id={findId}
            find={find}
            eras={eras}
            materials={materials}
            types={types}
            sites={sites}
            site={forSite}
            tags={tags}
          />
        )}
      </>
    );
  }
);

export { FindSettings };
