import React from 'react';
import { CircularProgress, Stack } from '@mui/material';

const spinnerContainerStyles = {
  width: '100%',
  height: '100%',
};

const FullScreenLoader = () => (
  <Stack
    sx={spinnerContainerStyles}
    direction="row"
    justifyContent="center"
    alignItems="center"
  >
    <CircularProgress />
  </Stack>
);

export { FullScreenLoader };
