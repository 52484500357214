import { useQuery } from '@apollo/client';
import { Link as MuiLink, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { GET_MY_FIND_QUERY } from '../../../gql';

interface FindListProps {
  selectedFind: string;
  onSelectFind: (id: string) => void;
}

const headingLinkStyles = {
  textDecoration: 'none',
};

const FindThumbImage = ({ alt, src }) => (
  <img
    alt={alt}
    src={src}
    style={{ objectFit: 'cover', maxHeight: 80, maxWidth: 80 }}
  />
);

const FindList = ({ selectedFind, onSelectFind }: FindListProps) => {
  const { data, loading } = useQuery(GET_MY_FIND_QUERY, {
    variables: { findId: selectedFind },
    fetchPolicy: 'cache-first',
    skip: !selectedFind,
  });

  if (loading || !data) {
    return null;
  }

  const find = data?.getMyFind;
  const image = find?.images?.[0];
  const amcrImage = find?.amcrData?.images?.[0];

  return (
    <Stack my={1}>
      <Stack direction="row" alignItems="stretch" gap={1.5}>
        {(image || amcrImage) && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            width={80}
            height={80}
          >
            <FindThumbImage
              alt={find.id}
              src={
                amcrImage
                  ? `https://digiarchiv.aiscr.cz/api/img/thumb?id=${amcrImage}`
                  : `https://imagedelivery.net/3zdDZwDRXumMqC2LPbSSXw/${image}/w=150`
              }
            />
          </Stack>
        )}
        <Stack alignSelf="center" justifyItems="flex-start">
          <MuiLink
            sx={headingLinkStyles}
            to={`/f/${find.id}/edit`}
            component={Link}
          >
            <Typography
              lineHeight={1.3}
              fontSize={14}
              fontWeight={600}
              variant="body1"
            >
              {find.type}
              <Typography
                lineHeight={1.3}
                component="span"
                variant="body1"
                fontSize={14}
              >
                , {find.material}
              </Typography>
            </Typography>
          </MuiLink>
          <Typography lineHeight={1.2} fontSize={14} variant="body1">
            {find.era}
          </Typography>
          <Typography lineHeight={1.2} fontSize={12} variant="body1">
            found:{' '}
            {find.foundAt
              ? new Date(find.foundAt).toLocaleDateString()
              : 'unknown date'}
          </Typography>
          <Typography lineHeight={1.2} fontSize={12} variant="body1">
            {find.latitude}, {find.longitude}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export { FindList };
