import React from 'react';
import { DesktopDrawer } from './DesktopDrawer';
import { MobileDrawer } from './MobileDrawer';
import { DrawerContent } from './DrawerContent';

const MapviewDrawer = ({ isMobile, onSignOut }) => {
  const handleLogoutClick = async () => {
    await onSignOut();
  };

  if (isMobile) {
    return (
      <MobileDrawer>
        <DrawerContent handleLogoutClick={handleLogoutClick} />
      </MobileDrawer>
    );
  }

  return (
    <DesktopDrawer>
      <DrawerContent handleLogoutClick={handleLogoutClick} />
    </DesktopDrawer>
  );
};

export { MapviewDrawer };
