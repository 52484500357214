import { gql } from '@apollo/client';

export const REMOVE_CONTACT_REQUEST_MUTATION = gql`
  mutation RemoveContactRequestMutation($id: String!) {
    removeContactRequest(id: $id) {
      ... on RemoveContactRequestSuccess {
        result
      }
      ... on ValidationError {
        errors {
          error
          path
        }
      }
    }
  }
`;
