import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { parseInitialSharedCoords } from '../helpers';
import { useLayer } from '../components/LayerContext/useLayer';
import { GET_SHARED_MAP_QUERY } from '../gql';
import { useEffect } from 'react';

const useLoadShared = ({ setSharedMap, isSignedIn }) => {
  const navigate = useNavigate();
  const { shareSlug } = useParams();
  const { setLayer } = useLayer();

  const { loading: sharedMapLoading } = useQuery(GET_SHARED_MAP_QUERY, {
    fetchPolicy: 'cache-first',
    variables: { slug: shareSlug },
    skip: !isSignedIn || !shareSlug,
    onCompleted: (d) => {
      const { initialCoords } = parseInitialSharedCoords(
        d?.getSharedMap?.settings?.mapSettings
      );

      setSharedMap({
        markerPoints: d?.getSharedMap?.settings?.markerPoints,
        sharedInitialCoordinates: initialCoords,
      });

      setLayer(d?.getSharedMap?.settings?.mapSettings?.layer);
    },
  });

  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return {
    sharedMapLoading,
  };
};

export { useLoadShared };
