import { gql } from '@apollo/client';

export const CREATE_SITE_MUTATION = gql`
  mutation CreateSiteMutation($site: SiteInput!) {
    createSite(site: $site) {
      ... on CreateSiteSuccess {
        site {
          id
          createdAt
          createdBy
          description
          eraId
          localName
          name
          longitude
          latitude
          shared
          siteType
          visibility
          updatedAt
        }
      }
      ... on ValidationError {
        errors {
          error
          path
        }
      }
    }
  }
`;
