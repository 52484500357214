import { gql } from '@apollo/client';

export const ADD_SITE_COLLABORATOR_MUTATION = gql`
  mutation AddSiteCollaboratorMutation(
    $siteId: String!
    $userId: String!
    $level: Int!
  ) {
    addSiteCollaborator(siteId: $siteId, userId: $userId, level: $level) {
      ... on AddSiteCollaboratorSuccess {
        result
      }
      ... on ValidationError {
        errors {
          error
          path
        }
      }
    }
  }
`;
