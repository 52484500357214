import { gql } from '@apollo/client';

export const DELETE_FIND_MUTATION = gql`
  mutation DeleteFindMutation($id: String!) {
    deleteFind(id: $id) {
      ... on DeleteFindSuccess {
        result
      }
      ... on ValidationError {
        errors {
          error
          path
        }
      }
    }
  }
`;
