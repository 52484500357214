import React, { ReactNode, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

const buttonStyles = {
  mx: 0,
};

const mbStyles = { mb: 2 };
const containerStyles = {
  p: 2,
  height: 1,
};

const BurgerMenu = ({ menu }: { menu: ReactNode }) => {
  const [open, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(open);
  };

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer(true)}
        sx={buttonStyles}
      >
        <MenuIcon />
      </IconButton>

      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <Box sx={containerStyles}>
          <IconButton sx={mbStyles}>
            <CloseIcon onClick={toggleDrawer(false)} />
          </IconButton>

          <Divider sx={mbStyles} />

          {menu}
        </Box>
      </Drawer>
    </>
  );
};

export { BurgerMenu };
