import { gql } from '@apollo/client';

export const GetMySiteQueryName = 'GetMySite';

export const GET_MY_SITE_QUERY = gql`
  query ${GetMySiteQueryName}($siteId: String!) {
    getMySite(siteId: $siteId) {
      id
      createdAt
      createdBy
      description
      eraId
      localName
      name
      longitude
      latitude
      shared
      siteType
      visibility
      updatedAt
    }
  }
`;
