import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import React from 'react';
import { DateTime } from 'luxon';

interface ContactRequestProps {
  data: { id: string; createdAt: string; email: string; displayName: string };
  onAccept: (id: string) => void;
  onRemove: (id: string) => void;
}

const moreButtonStyles = { marginLeft: 'auto' };

const ContactRequest = ({
  data: { id, createdAt, email, displayName },
  onAccept,
  onRemove,
}: ContactRequestProps) => {
  const handleAcceptClick = () => {
    onAccept(id);
  };
  const handleRemoveClick = () => {
    onRemove(id);
  };

  return (
    <Card sx={{ maxWidth: 200, minWidth: 150, flexGrow: 1, flexShrink: 1 }}>
      <CardActionArea>
        <CardContent>
          <Typography variant="body1" gutterBottom component="div">
            {email}
          </Typography>
          <Typography
            fontWeight={displayName ? 'bold' : 100}
            height={30}
            component="div"
            color={displayName ? 'text.primary' : 'text.secondary'}
          >
            {displayName || '—'}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            gutterBottom
            component="div"
          >
            Requested {DateTime.fromISO(createdAt).toRelative({ locale: 'en' })}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing>
        <Button size="small" color="primary" onClick={handleAcceptClick}>
          Accept
        </Button>
        <Button
          sx={moreButtonStyles}
          size="small"
          color="info"
          onClick={handleRemoveClick}
        >
          Remove
        </Button>
      </CardActions>
    </Card>
  );
};

const SentRequest = ({
  email,
  createdAt,
}: {
  email: string;
  createdAt: string;
}) => {
  return (
    <Card sx={{ maxWidth: 200, minWidth: 150, flexGrow: 1, flexShrink: 1 }}>
      <CardActionArea>
        <CardContent>
          <Typography variant="body1" gutterBottom component="div">
            {email}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            gutterBottom
            component="div"
          >
            Sent {DateTime.fromISO(createdAt).toRelative({ locale: 'en' })}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export { ContactRequest, SentRequest };
