import * as React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';

const boxStyles = { position: 'relative' };
const getColor = (color, isLightTheme) => {
  if (color) {
    return color;
  }

  return isLightTheme ? '#1a90ff' : '#308fe8';
};

const CustomCircularProgress = ({
  boxProps = {},
  ...progressProps
}: CircularProgressProps & { boxProps?: BoxProps }) => (
  <Box sx={boxStyles} {...boxProps}>
    <CircularProgress
      variant="determinate"
      sx={{
        color: (theme) =>
          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
      }}
      size={40}
      thickness={4}
      {...progressProps}
      value={100}
    />
    <CircularProgress
      variant="indeterminate"
      disableShrink
      sx={{
        color: (theme) =>
          getColor(progressProps.color, theme.palette.mode === 'light'),
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }}
      size={40}
      thickness={4}
      {...progressProps}
    />
  </Box>
);

export { CustomCircularProgress };
