import { gql } from '@apollo/client';

export const GetMyFindQueryName = 'GetMyFind';

export const GET_MY_FIND_QUERY = gql`
  query ${GetMyFindQueryName}($findId: String!) {
    getMyFind(findId: $findId) {
      id
      name
      evId
      createdAt
      description
      updatedAt
      foundAt
      material
      era
      type
      note
      localization
      latitude
      longitude
      siteId
      amcrId
      amcrData(cacheOnly: false) {
        images
      }
      shared
      tags
      visibility
      image
      images
    }
  }
`;
