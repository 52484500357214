import { Unstable_Grid2 as Grid } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { PreviewMap } from '../PreviewMap';

const PreviewMapContainer = ({ control }) => {
  const latitude = useWatch({
    control,
    name: "latitude",
  });
  const longitude = useWatch({
    control,
    name: "longitude",
  });
  if (
    [longitude, latitude].includes('') ||
    typeof longitude === 'undefined' ||
    typeof latitude === 'undefined'
  ) {
    return null;
  }

  return (
    <Grid
      justifyContent="flex-start"
      alignItems="flex-end"
      xs={12}
      sx={{ height: '200px' }}
    >
      <PreviewMap
        longitude={parseFloat(longitude)}
        latitude={parseFloat(latitude)}
      />
    </Grid>
  );
};

export { PreviewMapContainer };
