import { gql } from '@apollo/client';

export const UPDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateAccountMutation($user: UserInput!) {
    updateAccount(user: $user) {
      ... on UpdateAccountSuccess {
        viewer {
          user {
            email
            id
            slug
            role
            displayName
            firstName
            lastName
          }
        }
      }
      ... on ValidationError {
        errors {
          error
          path
        }
      }
    }
  }
`;
