import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { grey, red } from '@mui/material/colors';
import {
  Alert,
  Autocomplete,
  Button,
  Unstable_Grid2 as Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useApolloClient, useMutation } from '@apollo/client';

import {
  CREATE_FIND_MUTATION,
  GET_CLOUDFLARE_DIRECT_LINK_QUERY,
  GET_SITE_FINDS_QUERY,
  GetMyFindQueryName,
  UPDATE_FIND_MUTATION,
} from '../../gql';
import { handleUploadImage } from '../../io';
import { CustomCircularProgress } from '../CustomCiruclarProgress';
import { DeleteFindControl } from './DeleteFindControl';
import { ImageList } from './ImageList';
import { MultiDropzone } from '../MultiDropzone';
import { RequiredLabel } from '../RequiredLabel';
import { PreviewMapContainer } from './PreviewMapContainer';

interface FindFormProps {
  id: string;
  find: {
    name?: string;
    evId?: string;
    localization?: string;
    latitude?: string | number;
    longitude?: string | number;
    foundAt?: string;
    description?: string;
    type?: string;
    era?: string;
    material?: string;
    note?: string;
    visibility: 'public' | 'private';
    siteId?: string;
    amcrId?: string;
    amcrData?: { images: string[] };
    shared?: boolean;
    tags: string[];
    image?: string;
    images?: string[];
  };
  materials: string[];
  eras: { id: string; parentId: string }[];
  types: { id: string; category: string }[];
  sites: { id: string; name: string }[];
  tags: string[];
  site?: string;
}

const containerStyles = { width: '100%' };
const sectionStyles = {
  flex: 1,
  paddingTop: 2,
  paddingBottom: 6,
  paddingLeft: 2,
  paddingRight: 2,
  minWidth: 300,
};

const inputStyles = { width: '100%', padding: 0 };
const endAdornmentStyles = { marginRight: '12px' };
const calendarInputProps = {
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& > fieldset': {
      borderColor: grey[600],
      borderWidth: '1px',
      borderStyle: 'solid',
    },
    '&:hover > fieldset': {
      borderColor: grey[600],
      borderWidth: '1px',
      borderStyle: 'solid',
    },
    '& > input': {
      padding: '8.5px 12px',
    },
    borderRadius: '2px',
  },
};
const gpsInputStyles = { flex: 1 };
const labelStyles = { fontWeight: 600, fontSize: 14 };
const dangerStyles = { fontWeight: 600, fontSize: 14, color: red[800] };
const submitButtonStyles = {
  borderRadius: 2,
  fontSize: '1rem',
};
const successAlertStyles = { width: '100px' };
const customProgressProps = { lineHeight: 0.5, ml: 2 };

const isValidAmcrId = (value?: string) =>
  !!value && value.split('-').length === 3;

const FindForm = React.memo(
  ({ id, find, eras, materials, site, sites, tags, types }: FindFormProps) => {
    const [images, setImages] = useState([]);
    const navigate = useNavigate();
    const client = useApolloClient();
    const [
      updateFind,
      { called: calledUpdate, loading: loadingUpdate },
    ] = useMutation(UPDATE_FIND_MUTATION, {
      refetchQueries: [GetMyFindQueryName],
    });
    const [
      createFind,
      { called: calledCreate, loading: loadingCreate },
    ] = useMutation(CREATE_FIND_MUTATION, {
      onCompleted: ({ createFind: { __typename, find } }) => {
        // todo: instead of refetching GET_SITE_FINDS_QUERY, rather just add item to cache
        // because pagination might be different and this might break view or it has not been called before
        // so refetch might not make sense
      },
      refetchQueries: [
        {
          query: GET_SITE_FINDS_QUERY,
          variables: { siteId: site, pagination: { limit: 1000 } },
        },
      ],
    });

    const called = calledUpdate || calledCreate;
    const loading = loadingUpdate || loadingCreate;

    const defaultValues = find
      ? {
          name: find.name,
          evId: find.evId,
          localization: find.localization,
          description: find.description,
          foundAt: DateTime.fromISO(find.foundAt),
          era: find.era,
          type: find.type,
          material: find.material,
          note: find.note,
          latitude: find.latitude,
          longitude: find.longitude,
          siteId: find.siteId,
          amcrId: find.amcrId,
          public: find.visibility === 'public',
          tags: find.tags,
          imageFiles: undefined,
        }
      : {
          siteId: site,
        };
    const isShared = Boolean(find?.shared);

    const {
      reset,
      control,
      handleSubmit,
      getValues,
      setValue,
      formState: { isDirty, isSubmitting },
    } = useForm({
      defaultValues,
    });
    const onSubmit = async (data) => {
      let uploadedImages = [];
      const imageFiles = data.imageFiles;
      if (imageFiles && imageFiles.length > 0) {
        const linkQueriesResults = await Promise.all(
          imageFiles.map((file) =>
            client.query({
              query: GET_CLOUDFLARE_DIRECT_LINK_QUERY,
              fetchPolicy: 'no-cache',
              variables: { name: file.name },
            })
          )
        );

        for (const [index, linkQueryResult] of linkQueriesResults.entries()) {
          try {
            const {
              uploadURL,
              id,
            } = linkQueryResult.data.getCloudflareDirectLink;

            if (!uploadURL) {
              throw new Error('Failed to retrieve the direct upload URL.');
            }

            const result = await handleUploadImage(
              imageFiles[index],
              uploadURL,
              id
            );
            uploadedImages.push(result);
          } catch (error) {}
        }
      }

      if (id) {
        await updateFind({
          variables: {
            id,
            find: {
              name: data.name,
              evId: data.evId,
              description: data.description,
              note: data.note,
              type: data.type?.id,
              era: data.era?.id,
              material: data.material,
              foundAt: data.foundAt && data.foundAt.toISODate(),
              siteId: data.siteId,
              amcrId: data.amcrId?.trim(),
              localization: data.localization,
              latitude: data.latitude && parseFloat(data.latitude.toString()),
              longitude:
                data.longitude && parseFloat(data.longitude.toString()),
              visibility: data.public ? 'public' : 'private',
              tags: data.tags,
              images:
                find?.images || uploadedImages
                  ? [
                      ...(find?.images || []),
                      ...(uploadedImages || []).filter((i) => i),
                    ]
                  : find?.images,
            },
          },
        });
      } else {
        await createFind({
          variables: {
            find: {
              name: data.name,
              evId: data.evId,
              description: data.description,
              note: data.note,
              type: data.type?.id,
              era: data.era?.id,
              material: data.material,
              foundAt: data.foundAt && data.foundAt.toISODate(),
              siteId: data.siteId,
              amcrId: data.amcrId?.trim(),
              localization: data.localization,
              latitude: data.latitude && parseFloat(data.latitude.toString()),
              longitude:
                data.longitude && parseFloat(data.longitude.toString()),
              visibility: data.public ? 'public' : 'private',
              tags: data.tags,
              images:
                find?.images || uploadedImages
                  ? [
                      ...(find?.images || []),
                      ...uploadedImages.filter((i) => i),
                    ]
                  : find?.images,
            },
          },
          onCompleted: (createdData) => {
            if (createdData?.createFind?.find) {
              navigate(`/f/${createdData.createFind.find.id}/edit`);
            }
          },
        });
      }

      // add yup validation and error handling and displaying
      reset({ ...data, imageFiles: null });
      setImages([]);
    };

    const handleAcceptFiles = (files) => {
      if (files) {
        const value = getValues('imageFiles');
        setValue('imageFiles', [...(value || []), ...files], {
          shouldDirty: true,
        });
      }
    };

    return (
      <Stack
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        sx={containerStyles}
        gap={3}
      >
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          flexWrap="wrap"
          spacing={2}
          sx={containerStyles}
        >
          <Paper sx={sectionStyles}>
            <Typography variant="h5" gutterBottom>
              Find
            </Typography>
            <Grid container spacing={2}>
              <Grid justifyContent="flex-start" xs={12} md={6}>
                <Stack direction="column">
                  <InputLabel sx={labelStyles} htmlFor="find-name">
                    Find name
                  </InputLabel>
                  <Controller
                    disabled={isShared}
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <Input
                        id="find-name"
                        type="text"
                        sx={inputStyles}
                        {...field}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid justifyContent="flex-start" xs={12} md={6}>
                <Stack direction="column">
                  <InputLabel sx={labelStyles} htmlFor="find-evid">
                    EvID
                  </InputLabel>
                  <Controller
                    disabled={isShared}
                    name="evId"
                    control={control}
                    render={({ field }) => (
                      <Input
                        id="find-evid"
                        type="text"
                        sx={inputStyles}
                        {...field}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid justifyContent="flex-start" xs={12}>
                <Stack direction="column">
                  <InputLabel sx={labelStyles} htmlFor="find-description">
                    Description
                  </InputLabel>
                  <Controller
                    name="description"
                    disabled={isShared}
                    control={control}
                    render={({ field }) => (
                      <Input
                        multiline
                        minRows={2}
                        maxRows={2}
                        sx={inputStyles}
                        id="find-description"
                        {...field}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid justifyContent="flex-start" xs={12} md={6}>
                <Stack direction="column">
                  <InputLabel sx={labelStyles} htmlFor="find-type">
                    Find type
                  </InputLabel>
                  <Controller
                    name="type"
                    disabled={isShared}
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Autocomplete
                        disablePortal
                        id="find-type"
                        options={types}
                        groupBy={(option) => option.category}
                        getOptionLabel={(option: {
                          id: string;
                          category: string;
                        }) => option.id}
                        defaultValue={types.find(
                          (t) => t.id === defaultValues.type
                        )}
                        onChange={(event, newValue) => onChange(newValue)}
                        renderInput={(params) => (
                          <TextField
                            sx={inputStyles}
                            inputRef={ref}
                            {...params}
                            {...field}
                          />
                        )}
                        sx={inputStyles}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid justifyContent="flex-start" xs={12} md={6}>
                <Stack direction="column">
                  <InputLabel sx={labelStyles} htmlFor="find-material">
                    Material
                  </InputLabel>
                  <Controller
                    name="material"
                    disabled={isShared}
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Autocomplete
                        disablePortal
                        id="find-material"
                        options={materials}
                        defaultValue={defaultValues.material}
                        onChange={(event, newValue) => onChange(newValue)}
                        renderInput={(params) => (
                          <TextField
                            sx={inputStyles}
                            inputRef={ref}
                            {...params}
                            {...field}
                          />
                        )}
                        sx={inputStyles}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid justifyContent="flex-start" xs={12}>
                <Stack direction="column">
                  <InputLabel sx={labelStyles} htmlFor="find-era">
                    Era
                  </InputLabel>
                  <Controller
                    name="era"
                    disabled={isShared}
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Autocomplete
                        disablePortal
                        id="find-era"
                        options={eras}
                        groupBy={(option) => option.parentId}
                        getOptionLabel={(option: {
                          id: string;
                          parentId: string;
                        }) => option.id}
                        defaultValue={eras.find(
                          (t) => t.id === defaultValues.era
                        )}
                        onChange={(event, newValue) => onChange(newValue)}
                        renderInput={(params) => (
                          <TextField
                            sx={inputStyles}
                            inputRef={ref}
                            {...params}
                            {...field}
                          />
                        )}
                        sx={inputStyles}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid justifyContent="flex-start" xs={12}>
                <Stack direction="column">
                  <InputLabel sx={labelStyles} htmlFor="find-note">
                    Note
                  </InputLabel>
                  <Controller
                    name="note"
                    disabled={isShared}
                    control={control}
                    render={({ field }) => (
                      <Input
                        multiline
                        minRows={2}
                        maxRows={2}
                        sx={inputStyles}
                        id="find-note"
                        {...field}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            {/* Tags are outside of Grid, as they caused growing width bug */}
            <Stack my={2} direction="column">
              <InputLabel sx={labelStyles} htmlFor="find-tags">
                Tags
              </InputLabel>
              <Controller
                name="tags"
                disabled={isShared}
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    freeSolo
                    size="small"
                    multiple
                    id="find-tags"
                    options={tags}
                    defaultValue={tags.filter((t) =>
                      (defaultValues.tags || []).includes(t)
                    )}
                    onChange={(event, newValue) => onChange(newValue)}
                    renderInput={(params) => (
                      <TextField inputRef={ref} {...params} {...field} />
                    )}
                  />
                )}
              />
            </Stack>
          </Paper>

          <Paper sx={sectionStyles}>
            <Typography variant="h5" gutterBottom>
              Situation
            </Typography>
            <Grid container spacing={2}>
              <Grid justifyContent="flex-start" xs={12}>
                <Stack direction="column">
                  <InputLabel sx={labelStyles} htmlFor="find-localization">
                    Localization
                  </InputLabel>
                  <Controller
                    disabled={isShared}
                    name="localization"
                    control={control}
                    render={({ field }) => (
                      <Input
                        id="find-localization"
                        type="text"
                        sx={inputStyles}
                        {...field}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid justifyContent="flex-start" xs={12} md={6}>
                <Stack direction="column">
                  <InputLabel sx={labelStyles} htmlFor="find-date">
                    Date found <RequiredLabel />
                  </InputLabel>
                  <LocalizationProvider
                    adapterLocale="cs"
                    dateAdapter={AdapterLuxon}
                  >
                    <Controller
                      control={control}
                      name="foundAt"
                      render={({ field: { ref, onBlur, name, ...field } }) => (
                        <DatePicker
                          {...field}
                          inputRef={ref}
                          slotProps={{
                            textField: {
                              sx: calendarInputProps,
                              name,
                              onBlur,
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid justifyContent="flex-start" xs={12} md={6}>
                <Stack direction="column">
                  <InputLabel sx={labelStyles} htmlFor="find-site">
                    Site
                  </InputLabel>
                  <Controller
                    name="siteId"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Autocomplete
                        disablePortal
                        id="find-site"
                        options={sites}
                        disabled={isShared || !!site}
                        getOptionLabel={(option: {
                          id: string;
                          name: string;
                        }) => option.name}
                        defaultValue={sites.find(
                          (s) => s.id === defaultValues.siteId
                        )}
                        onChange={(event, newValue) => onChange(newValue)}
                        renderInput={(params) => (
                          <TextField
                            sx={inputStyles}
                            inputRef={ref}
                            {...params}
                            {...field}
                          />
                        )}
                        sx={inputStyles}
                      />
                    )}
                  />
                </Stack>
              </Grid>

              <Grid justifyContent="flex-start" alignItems="flex-end" xs={12}>
                <Stack direction="column" width="100%">
                  <InputLabel sx={labelStyles} htmlFor="find-latitude">
                    GPS <RequiredLabel />
                  </InputLabel>
                  <Stack spacing={2} direction="row" width="100%">
                    <Controller
                      name="latitude"
                      disabled={isShared}
                      control={control}
                      render={({ field }) => (
                        <Input
                          id="find-latitude"
                          type="text"
                          sx={gpsInputStyles}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="longitude"
                      disabled={isShared}
                      control={control}
                      render={({ field }) => (
                        <Input
                          id="find-longitude"
                          type="text"
                          sx={gpsInputStyles}
                          {...field}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Grid>

              <PreviewMapContainer control={control} />
            </Grid>
          </Paper>
        </Stack>

        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          flexWrap="wrap"
          spacing={2}
          sx={containerStyles}
        >
          <Paper sx={sectionStyles}>
            <Typography variant="h5" gutterBottom>
              Settings
            </Typography>
            <Grid container spacing={2}>
              <Grid justifyContent="flex-start" xs={12}>
                <Stack direction="column">
                  <InputLabel sx={labelStyles} htmlFor="find-amcr-id">
                    AMCR-ID
                  </InputLabel>
                  <Controller
                    disabled={isShared}
                    name="amcrId"
                    control={control}
                    render={({ field }) => (
                      <Input
                        id="find-amcr-id"
                        type="text"
                        sx={inputStyles}
                        {...field}
                        endAdornment={
                          isValidAmcrId(field.value) ? (
                            <InputAdornment
                              position="end"
                              sx={endAdornmentStyles}
                            >
                              <IconButton
                                LinkComponent={Link}
                                aria-label="open find on AMCR public page"
                                edge="end"
                                href={`https://digiarchiv.aiscr.cz/id/${field.value}`}
                                target="_blank"
                              >
                                <OpenInNewIcon />
                              </IconButton>
                            </InputAdornment>
                          ) : null
                        }
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid justifyContent="flex-start" xs={12}>
                <Stack direction="column">
                  <InputLabel sx={labelStyles} htmlFor="find-visibility">
                    Public
                  </InputLabel>
                  <Controller
                    name="public"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        id="find-visibility"
                        disabled={isShared}
                        onChange={(e) => onChange(e.target.checked)}
                        checked={value}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              {!!id && (
                <Grid justifyContent="flex-start" xs={12}>
                  <Stack direction="column">
                    <Typography sx={dangerStyles}>Danger zone</Typography>
                    <DeleteFindControl id={id} siteId={defaultValues.siteId} />
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Paper>
          <Paper sx={sectionStyles}>
            <Typography variant="h5" gutterBottom>
              Images
            </Typography>
            <MultiDropzone
              files={images}
              setFiles={setImages}
              onFilesAccept={handleAcceptFiles}
            />
            {!!id && (
              <ImageList
                amcrImages={find?.amcrData?.images}
                images={find.images}
                mainImage={find.image}
              />
            )}
          </Paper>
        </Stack>
        <Grid container spacing={2}>
          <Grid justifyContent="flex-start" alignItems="center" xs={8}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Button
                disabled={loading || isSubmitting || !isDirty || isShared}
                type="submit"
                variant="contained"
                sx={submitButtonStyles}
              >
                {!isSubmitting ? (
                  'Submit'
                ) : (
                  <Stack direction="row" alignItems="center">
                    Submitting{' '}
                    <CustomCircularProgress
                      color="inherit"
                      boxProps={customProgressProps}
                      size={20}
                    />{' '}
                  </Stack>
                )}
              </Button>
              {called && !isDirty && (
                <Alert sx={successAlertStyles} severity="success">
                  Saved!
                </Alert>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    );
  }
);

export { FindForm };
