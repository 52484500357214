import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import TextField from '@mui/material/TextField';
import Content from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { CircularProgress, IconButton, Stack } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useWorkspace } from '../context/WorkspaceContext/useWorkspace';
import { useLayer } from '../LayerContext/useLayer';
import { getItem } from '../../io';
import { GET_SHARE_LINK_QUERY } from '../../gql';

interface DialogContentProps {
  onCopy: (value: boolean) => void;
}

const inputProps = {
  sx: {
    '& .MuiInputBase-input': {
      cursor: 'pointer',
    },
  },
};

const buttonStyles = {
  flexShrink: 0,
};

const DialogContent = ({ onCopy }: DialogContentProps) => {
  useEffect(() => {
    onCopy(false);
    return () => {
      onCopy(false);
    };
  }, [onCopy]);

  const {
    store: { markerPoints },
  } = useWorkspace();
  const { layer } = useLayer();
  const coordinates = getItem('initialCoordinates');

  const variables = {
    markerPoints,
    mapSettings: {
      layer,
      longitude: parseFloat(coordinates.split(',')[0]),
      latitude: parseFloat(coordinates.split(',')[1]),
      zoom: parseFloat(coordinates.split(',')[2]),
    },
  };

  const { data: shareLinkData, loading: shareLinkLoading } = useQuery(
    GET_SHARE_LINK_QUERY,
    {
      variables,
      fetchPolicy: 'cache-first',
    }
  );

  const inputRef = React.useRef();
  const handleTextFieldClick = (event: React.MouseEvent<HTMLDivElement>) => {
    navigator.clipboard.writeText(
      (event.nativeEvent.target as HTMLInputElement).value
    );
    onCopy(true);
  };

  const handleButtonClick = () => {
    navigator.clipboard.writeText((inputRef.current as HTMLInputElement).value);
    onCopy(true);
  };

  if (shareLinkLoading) {
    return (
      <Content>
        <Stack
          width="100%"
          height="100%"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Stack>
      </Content>
    );
  }

  const url = `https://acolyte.cz/m/${shareLinkData?.getShareLink?.toString()}`;

  return (
    <Content>
      <DialogContentText fontSize={14}>
        You are sharing current map view settings and selected points. Other
        users will not see your finds and sites.
      </DialogContentText>
      <Stack gap={1} direction="row" alignItems="center">
        <TextField
          margin="dense"
          id="share-map-input"
          fullWidth
          variant="standard"
          inputRef={inputRef}
          value={url}
          InputProps={inputProps}
          onClick={handleTextFieldClick}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
        />
        <IconButton onClick={handleButtonClick} sx={buttonStyles}>
          <ContentCopyIcon />
        </IconButton>
      </Stack>
    </Content>
  );
};

export { DialogContent };
