import { gql } from '@apollo/client';

export const GetSiteFindsQueryName = 'GetSiteFinds';

export const GET_SITE_FINDS_QUERY = gql`
  query ${GetSiteFindsQueryName}($siteId: String!, $filters: Filters, $pagination: Pagination) {
    getSiteFinds(siteId: $siteId, filters: $filters, pagination: $pagination) {
      id
      evId
      createdAt
      description
      updatedAt
      foundAt
      material
      era
      type
      note
      siteId
      latitude
      longitude
      shared
      visibility
      image
      images
      amcrId
      amcrData {
        images
      }
    }
  }
`;
