import React from 'react';
import Helmet from 'react-helmet';
import { Container, Stack, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';

import { Header } from '../../components';
import { AUTH_QUERY } from '../../gql';
import { UserSettingsForm } from '../../components/UserSettingsForm';
import { AccountSkeleton } from './AccountSkeleton';

const Account = () => {
  const { data, loading } = useQuery(AUTH_QUERY, { fetchPolicy: 'cache-only' });
  const user = data ? data.viewer.user : null;

  if (loading) {
    return <AccountSkeleton />;
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Helmet title="Acolyte"></Helmet>
      <Header />
      <Container maxWidth="xl">
        <Stack gap={2}>
          <Typography fontWeight="bold" variant="h4" gutterBottom>
            Account settings
          </Typography>
          <UserSettingsForm user={user} />
        </Stack>
      </Container>
    </div>
  );
};
export { Account };
