import { HSLColor } from 'd3-color';
import { Find, FindColorFunctionSettings, FindPoint } from '../types';
import { getMaterialColor } from '../../../helpers';
import { COLOR_FUNCTION_TYPES } from '../consts';

// Dynamic import of D3 dependencies
async function createColorScale(startColor: string, endColor: string) {
  const { scaleLinear } = await import('d3-scale');
  const { hsl } = await import('d3-color');
  const { interpolateHsl } = await import('d3-interpolate');

  // Create a color scale using d3
  return scaleLinear<HSLColor>()
    .domain([0, 1])
    .interpolate(interpolateHsl)
    .range([hsl(startColor), hsl(endColor)]);
}

async function assignColorsToFindsByFoundAt(
  points: Find[],
  {
    startColor = 'rgb(0, 255, 225)',
    endColor = 'rgb(255, 0, 0)',
  }: FindColorFunctionSettings
): Promise<FindPoint[]> {
  if (points.length === 0) return [];

  // Parse dates and sort points by date
  const dates = points
    .filter((p) => p.foundAt)
    .map((point) => new Date(point.foundAt));
  dates.sort((a, b) => a.getTime() - b.getTime());

  const startDate = dates[0].getTime();
  const endDate = dates[dates.length - 1].getTime();
  const range = endDate - startDate;

  // Create a color scale dynamically
  const colorScale = await createColorScale(startColor, endColor);
  const { rgb } = await import('d3-color');

  return points.map((point) => {
    if (point.foundAt) {
      const dateValue = new Date(point.foundAt).getTime();
      const normalizedValue = (dateValue - startDate) / range;
      const color = rgb(colorScale(normalizedValue));

      // Convert the color to an array of [r, g, b] values
      const rgbArray: [number, number, number] = [color.r, color.g, color.b];

      return {
        ...point,
        color: rgbArray,
      };
    }
    return {
      ...point,
      color: [0, 0, 0],
    };
  });
}

const assignColorsToFindsByMaterial = (points: Find[]): FindPoint[] =>
  points.map((p) => ({ ...p, color: getMaterialColor(p.material, p.draft) }));

const defaultFindColorFunctions = {
  defaultMaterial: {
    function: assignColorsToFindsByMaterial,
    settings: { type: COLOR_FUNCTION_TYPES.map },
    id: 'defaultMaterial',
    label: 'Default materials',
  },
  defaultTimeGradient: {
    function: assignColorsToFindsByFoundAt,
    settings: { type: COLOR_FUNCTION_TYPES.gradient },
    id: 'defaultTimeGradient',
    label: 'Find date gradient',
  },
};

export {
  assignColorsToFindsByFoundAt,
  assignColorsToFindsByMaterial,
  defaultFindColorFunctions,
};
