import React from 'react';
import Helmet from 'react-helmet';
import { useParams, Route, Routes } from 'react-router-dom';
import { Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useQuery } from '@apollo/client';

import { Breadcrumbs, BurgerMenu, Header, Main } from '../../components';
import { GET_MY_FIND_QUERY } from '../../gql';
import { FindImages } from '../../components/FindImages';
import { FindSettings } from '../../components/FindSettings';
import { FindMenu } from './FindMenu';
import { FindActionBar } from './FindActionBar';

const Find = React.memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { findId, siteId } = useParams();
  const { called, data, loading } = useQuery(GET_MY_FIND_QUERY, {
    variables: { findId },
    fetchPolicy: 'cache-first',
    skip: !findId,
  });

  const find = data?.getMyFind;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Helmet title="Sites | Acolyte"></Helmet>
      <Header mainIcon={isMobile && <BurgerMenu menu={<FindMenu />} />} />
      <Container maxWidth="xl">
        <Stack direction="row">
          {!isMobile && <FindMenu />}
          <Stack flexGrow={1} gap={1}>
            <Stack
              direction={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              alignItems={isMobile ? 'flex-start' : 'center'}
            >
              <Breadcrumbs />
              {findId && <FindActionBar siteId={siteId || find?.siteId} />}
            </Stack>
            <Main>
              <Routes>
                <Route
                  index
                  element={
                    <FindSettings
                      findId={findId}
                      called={called}
                      loading={loading}
                      find={find}
                    />
                  }
                />
                <Route
                  path="images"
                  element={
                    <FindImages
                      findId={findId}
                      called={called}
                      loading={loading}
                      find={find}
                    />
                  }
                />
              </Routes>
            </Main>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
});

export { Find };
