import { Box, IconButton, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';

interface MarkerPoint {
  id: string;
  label: string;
  coordinate: [number, number];
}

interface MarkerPointListProps {
  markerPoints: MarkerPoint[];
  onMarkerPointsClear: () => void;
  onMarkerPointDelete: (id: string) => void;
}

const markerPointContainerStyles = {
  '& .MuiIconButton-root': {
    visibility: 'hidden',
  },
  '&:hover .MuiIconButton-root': {
    visibility: 'visible',
  },
};

const markerBoxStyle = {
  position: 'relative',
  height: '40px',
  width: '36px',
  backgroundImage:
    'url("https://acolyte-images.s3.eu-central-1.amazonaws.com/icon-atlas.png")',
  backgroundSize: '150px 150px',
  backgroundPosition: 'top right 112px',
};

const deleteAllButtonStyles = { padding: '4px' };
const deleteMarkerButtonStyles = { padding: '6px' };
const deleteMarkerIconStyles = { fontSize: '16px' };

const MarkerText = styled('span')`
  user-select: none;
  font-family: monospace;
  margin-top: 9px;
`;

const MarkerImage = ({ index }) => (
  <Box sx={markerBoxStyle}>
    <Stack
      direction="row"
      position="absolute"
      width="100%"
      height="100%"
      justifyContent="center"
    >
      <MarkerText>{index + 1}</MarkerText>
    </Stack>
  </Box>
);

const MarkerPointList = ({
  markerPoints,
  onMarkerPointsClear,
  onMarkerPointDelete,
}: MarkerPointListProps) => {
  return (
    <Stack gap={1} my={1}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography fontWeight={500} fontSize={14}>
          Selected points
        </Typography>
        <IconButton onClick={onMarkerPointsClear} sx={deleteAllButtonStyles}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Stack>
      {markerPoints.map((point, index) => (
        <Stack
          key={point.id}
          sx={markerPointContainerStyles}
          direction="row"
          alignItems="stretch"
          gap={1.5}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            width={45}
            height={70}
          >
            <MarkerImage index={index} />
          </Stack>

          <Stack flexGrow={1} alignSelf="center" justifyItems="flex-start">
            <Typography fontSize={14} fontWeight={600} variant="body1">
              {point.label}
            </Typography>
            <Typography
              lineHeight={1.2}
              fontSize={12}
              variant="body1"
              color={grey[600]}
            >
              {point.coordinate[1]}, {point.coordinate[0]}
            </Typography>
            <Typography
              lineHeight={1.2}
              fontSize={12}
              variant="body1"
              color={grey[600]}
            >
              base layer
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            flexShrink={0}
            flexGrow={0}
            width={28}
          >
            <IconButton
              onClick={() => onMarkerPointDelete(point.id)}
              sx={deleteMarkerButtonStyles}
            >
              <DeleteIcon sx={deleteMarkerIconStyles} />
            </IconButton>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export { MarkerPointList };
