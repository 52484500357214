import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { DELETE_FIND_MUTATION } from '../../gql';

const deleteButtonStyles = {
  borderRadius: 2,
};

const labelProps = {
  typography: {
    sx: {
      fontSize: 14,
    },
  },
};

const labelContainerStyles = { margin: 0 };
const containerStyles = {
  padding: 2,
  backgroundColor: '#f9faff',
  marginTop: 1,
};

const DeleteFindControl = React.memo(
  ({ id, siteId }: { id: string; siteId?: string }) => {
    const [active, setActive] = useState(false);
    const navigate = useNavigate();

    const [
      deleteFind,
      { loading: loadingDelete, called: calledDelete },
    ] = useMutation(DELETE_FIND_MUTATION, {
      onCompleted: () => {
        // todo: for groups, add group id and resolve IF siteId ...or IF groupId
        if (siteId) {
          navigate(`/s/${siteId}/finds`);
        }
      },
    });

    const handleCheckboxClick = () => {
      setActive(!active);
    };

    const handleDeleteClick = async () => {
      await deleteFind({ variables: { id } });
    };

    return (
      <Stack sx={containerStyles} direction="row" gap={2}>
        <FormControlLabel
          sx={labelContainerStyles}
          componentsProps={labelProps}
          control={
            <Checkbox
              checked={active}
              onChange={handleCheckboxClick}
              size="small"
            />
          }
          label="Delete find"
          labelPlacement="start"
        />
        <Button
          sx={deleteButtonStyles}
          variant="outlined"
          startIcon={<DeleteIcon />}
          disabled={!active || loadingDelete || calledDelete}
          onClick={handleDeleteClick}
        >
          Permanently delete
        </Button>
      </Stack>
    );
  }
);

export { DeleteFindControl };
