import React from 'react';
import { Button, Stack } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';

const SiteListControls = () => {
  return (
    <Stack mb={2} direction="row" justifyContent="space-between">
      <Button
        component={Link}
        size="small"
        variant="outlined"
        startIcon={<AddCircleIcon />}
        to="/s/create"
      >
        Create
      </Button>
      <Button component={Link} size="small" variant="outlined" to="/s">
        View all
      </Button>
    </Stack>
  );
};

export { SiteListControls };
