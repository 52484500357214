import React, { useCallback } from 'react';

type Marker = { id: string; label: string; coordinate: [number, number] };

export enum MapTools {
  ADD_MARKER_POINT_TOOL = 'ADD_MARKER_POINT_TOOL',
}

type WorkspaceStoreState = {
  activeMapTool: MapTools | null;
  markerPoints: Marker[];
  sharedInitialCoordinates: string;
  shareMapDialogOpen: boolean;
};

enum WorkspaceActionType {
  SET_ACTIVE_MAP_TOOL = 'SET_ACTIVE_MAP_TOOL',
  ADD_MARKER_POINT = 'ADD_MARKER_POINT',
  SET_SHARED_MAP = 'SET_SHARED_MAP',
  DELETE_MARKER_POINT = 'DELETE_MARKER_POINT',
  SET_SHARE_MAP_DIALOG_OPEN = 'SET_SHARE_MAP_DIALOG_OPEN',
  CLEAR_MARKER_POINTS = 'CLEAR_MARKER_POINTS',
}

type WorkspaceStoreAction = {
  type: WorkspaceActionType;
  payload?: any;
};

const workspaceReducer = (
  state: WorkspaceStoreState,
  action: WorkspaceStoreAction
) => {
  switch (action.type) {
    case 'ADD_MARKER_POINT':
      const newMarker = {
        id: new Date().toISOString(),
        ordinal: state.markerPoints.length + 1,
        ...action.payload,
      };
      return {
        ...state,
        markerPoints: [...state.markerPoints, newMarker],
      };
    case 'SET_SHARED_MAP':
      return {
        ...state,
        sharedInitialCoordinates: action.payload.sharedInitialCoordinates,
        markerPoints: (action.payload.markerPoints || state.markerPoints).map(
          (m, i) => ({
            ...m,
            ordinal: i + 1,
          })
        ),
      };
    case 'DELETE_MARKER_POINT':
      const updatedPoints = state.markerPoints
        .filter((m) => m.id !== action.payload)
        .map((m, index) => ({ ...m, ordinal: index + 1 }));
      return {
        ...state,
        markerPoints: updatedPoints,
      };
    case 'CLEAR_MARKER_POINTS':
      return {
        ...state,
        markerPoints: [],
        activeMapTool: null,
      };
    case 'SET_ACTIVE_MAP_TOOL':
      return {
        ...state,
        activeMapTool: action.payload,
      };
    case 'SET_SHARE_MAP_DIALOG_OPEN':
      return {
        ...state,
        shareMapDialogOpen: action.payload,
      };
    default:
      return state;
  }
};

function createCtx(defaultValue: WorkspaceStoreState) {
  const ctx = React.createContext({
    store: defaultValue,
    setActiveMapTool: null,
    setSharedMap: null,
    addMarkerPoint: null,
    deleteMarkerPoint: null,
    onShareMap: null,
    onShareMapDialogClose: null,
    clearMarkerPoints: null,
  });

  function WorkspaceProvider(props: React.PropsWithChildren<{}>) {
    const [store, dispatch] = React.useReducer(workspaceReducer, defaultValue);

    const setActiveMapTool = useCallback(
      (options) => {
        dispatch({
          type: WorkspaceActionType.SET_ACTIVE_MAP_TOOL,
          payload: options,
        });
      },
      [dispatch]
    );

    const addMarkerPoint = useCallback(
      (options) => {
        dispatch({
          type: WorkspaceActionType.ADD_MARKER_POINT,
          payload: options,
        });
      },
      [dispatch]
    );

    const clearMarkerPoints = useCallback(() => {
      dispatch({
        type: WorkspaceActionType.CLEAR_MARKER_POINTS,
        payload: [],
      });
    }, [dispatch]);

    const setSharedMap = useCallback(
      (options) => {
        dispatch({
          type: WorkspaceActionType.SET_SHARED_MAP,
          payload: options,
        });
      },
      [dispatch]
    );

    const deleteMarkerPoint = useCallback(
      (options) => {
        dispatch({
          type: WorkspaceActionType.DELETE_MARKER_POINT,
          payload: options,
        });
      },
      [dispatch]
    );

    const onShareMap = useCallback(() => {
      dispatch({
        type: WorkspaceActionType.SET_SHARE_MAP_DIALOG_OPEN,
        payload: true,
      });
    }, [dispatch]);

    const onShareMapDialogClose = useCallback(() => {
      dispatch({
        type: WorkspaceActionType.SET_SHARE_MAP_DIALOG_OPEN,
        payload: false,
      });
    }, [dispatch]);

    const value = React.useMemo(
      () => ({
        store,
        addMarkerPoint,
        deleteMarkerPoint,
        clearMarkerPoints,
        setActiveMapTool,
        setSharedMap,
        onShareMap,
        onShareMapDialogClose,
      }),
      [
        store,
        addMarkerPoint,
        deleteMarkerPoint,
        clearMarkerPoints,
        setActiveMapTool,
        setSharedMap,
        onShareMap,
        onShareMapDialogClose,
      ]
    );

    return <ctx.Provider value={value} {...props} />;
  }

  return [ctx, WorkspaceProvider] as [typeof ctx, typeof WorkspaceProvider];
}

const [WorkspaceContext, WorkspaceProvider] = createCtx({
  activeMapTool: null,
  markerPoints: [],
  sharedInitialCoordinates: null,
  shareMapDialogOpen: false,
});

export { WorkspaceContext, WorkspaceProvider };
