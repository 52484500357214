import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
// @ts-ignore
import { createUploadLink } from 'apollo-upload-client';
import React from 'react';
import { hydrateRoot, Root } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import fetch from 'unfetch';
import { App } from './App';

declare global {
  interface Window {
    acolyteRoot: Root;
  }
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://c4c4f34b28e841eea056b7c5b5792b46@o4504498826379264.ingest.sentry.io/4504498829000704',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const client = new ApolloClient({
  cache: new InMemoryCache().restore((window as any).__D),
  link: createUploadLink({
    credentials: 'same-origin',
    fetch,
    uri: '/api',
  }),
});

loadableReady().then(() => {
  const content = (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  );

  if (process.env.NODE_ENV === 'production') {
    hydrateRoot(document.getElementById('root'), content);
  } else {
    if (window.acolyteRoot) {
      window.acolyteRoot.render(content);
    } else {
      window.acolyteRoot = hydrateRoot(
        document.getElementById('root'),
        content
      );
    }
  }
});

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept();
}
