import { gql } from '@apollo/client';

export const CREATE_CONTACT_MUTATION = gql`
  mutation CreateContactRequestMutation($email: String!) {
    createContactRequest(email: $email) {
      ... on CreateContactSuccess {
        result
      }
      ... on ValidationError {
        errors {
          error
          path
        }
      }
    }
  }
`;
