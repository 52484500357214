import * as React from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const toggleGroupStyles = {
  '& .MuiToggleButtonGroup-grouped': {
    height: '32px',
  },
  '& .MuiToggleButtonGroup-grouped > svg': {
    fontSize: '1.25rem',
  },
};

const ViewModeToggle = ({ setFindListMode, findListMode }) => {
  const handleToggleMode = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setFindListMode(newAlignment);
  };

  return (
    <ToggleButtonGroup
      sx={toggleGroupStyles}
      size="small"
      value={findListMode}
      exclusive
      onChange={handleToggleMode}
      aria-label="find list mode"
    >
      <ToggleButton value="grid" aria-label="list grid">
        <ViewListIcon />
      </ToggleButton>
      <ToggleButton value="gallery" aria-label="gallery">
        <ViewModuleIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export { ViewModeToggle };
