import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { Box, Container, Stack, Typography } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';

import {
  Contact,
  ContactRequest,
  CreateContactCard,
  Header,
  SentRequest,
} from '../../components';
import {
  ACCEPT_CONTACT_MUTATION,
  GET_MY_CONTACTS_QUERY,
  REMOVE_CONTACT_REQUEST_MUTATION,
} from '../../gql';
import { NoContacts } from '../../components/EmptyStates';

const Contacts = () => {
  const [displayCreate, setDisplayCreate] = useState(false);
  const { data: contactsData, loading } = useQuery(GET_MY_CONTACTS_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const [acceptContact] = useMutation(ACCEPT_CONTACT_MUTATION, {
    refetchQueries: [{ query: GET_MY_CONTACTS_QUERY }],
  });

  const [removeContactRequest] = useMutation(REMOVE_CONTACT_REQUEST_MUTATION, {
    refetchQueries: [{ query: GET_MY_CONTACTS_QUERY }],
  });

  const onAccept = async (id: string) => {
    await acceptContact({
      variables: {
        id,
      },
    });
  };

  const onRemove = async (id: string) => {
    await removeContactRequest({
      variables: {
        id,
      },
    });
  };

  const noRequestAction = {
    onClick: () => setDisplayCreate(true),
    label: 'Create first',
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Helmet title="Acolyte"></Helmet>
      <Header />
      <Container maxWidth="xl">
        <Stack spacing={4}>
          <Box>
            <Typography fontWeight="bold" variant="h4" gutterBottom>
              Contacts
            </Typography>
            <Stack flexWrap="wrap" gap={2} direction="row">
              {(contactsData?.getMyContacts?.contacts || []).map((contact) => (
                <Contact key={contact.email} data={contact} />
              ))}
              {contactsData?.getMyContacts?.contacts?.length === 0 && (
                <NoContacts>No contacts</NoContacts>
              )}
            </Stack>
          </Box>
          <Box>
            <Typography fontWeight="bold" variant="h4" gutterBottom>
              Contact requests
            </Typography>
            <Stack flexWrap="wrap" gap={2} direction="row">
              {contactsData?.getMyContacts?.contactRequests?.length > 0 && (
                <CreateContactCard />
              )}
              {(contactsData?.getMyContacts?.contactRequests || []).map(
                (contactRequest) => (
                  <ContactRequest
                    onAccept={onAccept}
                    onRemove={onRemove}
                    key={contactRequest.email}
                    data={contactRequest}
                  />
                )
              )}
              {!displayCreate &&
                contactsData?.getMyContacts?.contactRequests?.length === 0 && (
                  <NoContacts action={noRequestAction}>
                    No contact requests
                  </NoContacts>
                )}
              {displayCreate &&
                contactsData?.getMyContacts?.contactRequests?.length === 0 && (
                  <CreateContactCard />
                )}
            </Stack>
          </Box>
          {contactsData?.getMyContacts?.sentRequests?.length !== 0 && (
            <Box>
              <Typography fontWeight="bold" variant="h4" gutterBottom>
                Sent requests
              </Typography>
              <Stack flexWrap="wrap" gap={2} direction="row">
                {(contactsData?.getMyContacts?.sentRequests || []).map(
                  (request) => (
                    <SentRequest
                      key={request.email}
                      email={request.email}
                      createdAt={request.createdAt}
                    />
                  )
                )}
              </Stack>
            </Box>
          )}
        </Stack>
      </Container>
    </div>
  );
};

export { Contacts };
