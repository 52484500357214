import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import loadable from '@loadable/component';

const Tools = loadable(() => import('./Tools').then((mod) => mod.Tools), {
  ssr: false,
});

const accordionStyles = {
  '&:before': {
    display: 'none',
  },
};
const accordionContentStyles = { p: 0 };
const summaryStyles = { p: 0, fontSize: 14, fontWeight: 500 };

const FindLayersSettings = () => {
  return (
    <Accordion disableGutters sx={accordionStyles} elevation={0}>
      <AccordionSummary
        sx={summaryStyles}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        Settings
      </AccordionSummary>
      <AccordionDetails sx={accordionContentStyles}>
        <Tools />
      </AccordionDetails>
    </Accordion>
  );
};

export { FindLayersSettings };
