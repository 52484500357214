import { Box, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { CSSProperties, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

export interface FileWithPreview extends File {
  preview: string;
}
interface MultiDropzoneProps {
  onFilesAccept: (files: File[]) => void;
  files: FileWithPreview[];
  setFiles: (files: FileWithPreview[]) => void;
}

const dropzoneStyles = {
  border: `1px dashed ${grey[300]}`,
  background: grey[100],
  color: grey[400],
};

const addPhotoIconStyles = {
  verticalAlign: 'text-top',
  mx: 1,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
} as CSSProperties;

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
} as CSSProperties;

const MultiDropzone = ({
  onFilesAccept,
  files,
  setFiles,
}: MultiDropzoneProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
    onDrop: async (acceptedFiles) => {
      const hashes = files.map((f) => `${f.name}-${f.type}-${f.size}`);
      const filteredFiles = acceptedFiles.filter(
        (f) => !hashes.includes(`${f.name}-${f.type}-${f.size}`)
      );
      setFiles([
        ...files,
        ...filteredFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);

      await onFilesAccept(filteredFiles);
    },
  });

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt={`thumb-no-${index + 1}`}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <Stack gap={1}>
      <Box
        p={3}
        sx={dropzoneStyles}
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />
        <Typography textAlign="center">
          <AddPhotoAlternateIcon sx={addPhotoIconStyles} fontSize="small" />
          Drag 'n' drop some files here, or click to select files (jpg/png)
        </Typography>
      </Box>
      <Box>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </Box>
    </Stack>
  );
};

export { MultiDropzone };
