import React from 'react';
import { styled } from '@mui/material/styles';
import { Drawer, DrawerProps } from '@mui/material';
import { useSettings } from '../../context/SettingsContext/useSettings';

const EnhancedDrawer = styled(Drawer)<DrawerProps>(({ theme, open }) => ({
  width: open ? 400 : 0,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    borderColor: 'rgba(0, 0, 0, 0.03)',
    width: 400,
    boxSizing: 'border-box',
  },
  transition: theme.transitions.create(['width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DesktopDrawer = ({ children }) => {
  const {
    store: { mapDrawerOpen },
  } = useSettings();

  return (
    <EnhancedDrawer variant="persistent" anchor="right" open={mapDrawerOpen}>
      {children}
    </EnhancedDrawer>
  );
};

export { DesktopDrawer };
