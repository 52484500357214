import { gql } from '@apollo/client';

export const GetSharedMapQueryName = 'GetSharedMap';

export const GET_SHARED_MAP_QUERY = gql`
  query ${GetSharedMapQueryName}($slug: String!) {
    getSharedMap(slug: $slug) {
      settings {
        mapSettings {
          layer
          latitude
          longitude
          zoom
        }
        markerPoints {
          id
          label
          layer
          coordinate
          ordinal
        }
      }
    }
  }
`;
